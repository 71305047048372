import React, {Suspense, useEffect, useState} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Drawer from '@mui/material/Drawer';

import store from '../../src/store';

import {setCurrentPathname, showSystemErrorBlock, showToastMessage} from "./AppActions";
import {getMyProfile, leaveApplication} from "../user/userActions";
import {isEmpty} from "../utils/validations";
import {setPrevLocation} from "../AppComponents/AppActions";
import {userConst} from "../../src/user/userConsts";
import {getAuthorizationToken, removeAuthorizationToken} from "../../src/utils/httpHelper";
import {resetLoggedOutState, setUserAuthStatus, setUserProfile} from "../../src/user/userReducer";

import SiteUnderMaintenance from "./SiteUnderMaintenance";
import PageNotAccessible from "../core/components/pageNotAccessible/PageNotAccessible";
import LoggedOutUser from "../core/components/loggedOutUser/LoggedOutUser";
import ResAppBar from "./appResappBar/AppResAppBar";
import DLLoader from "../core/components/DLLoader/DLLoader";
import DLToastMessage from "../core/components/DLToastMessage/DLToastMessage";
import PreLoginRoute from "../core/webRouteWrapper/PreLoginRoute";
import PrePostLoginRoute from "../core/webRouteWrapper/PrePostLoginRoute";
import PostLoginRoute from "../core/webRouteWrapper/PostLoginRoute";


import Login from "../user/logIn/components/Login";
import Onbording from "../../src/onboarding/Onboarding";
import Leads from "../../src/leads/Leads";
import Clients from "../clients/Clients";
import HomePage from "../../src/home/HomePage";
import LeadDetails from "../leads/components/leadsDetails/LeadsDetails";
import AvailableDocumentList from "../onboarding/components/uploadDocuments/uploadDocuments";
import onboardingDetails from '../onboarding/components/onboardingDetails/OnboardingDetails'
import OnboardingWizard from '../onboarding/components/onboardingWizard/OnboardingWizard'
import OnboardingsListing from "../onboarding/listing/OnboardingsListing";
import LeadsCreate from "../leads/components/leadsCreate/LeadsCreate";
import {changeAppRoomJoinedStatus} from "./AppReducer";


const AppNavigator = (props) => {

    const {isMobileView, userAuthStatus, setUserProfile, setUserAuthStatus} = props;

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isProfileSet, setIsProfileSet] = useState(true);
    const [isSiteUnderMaintenance, setIsSiteUnderMaintenance] = useState(false);


    useEffect(() => {
        let authResponse = getAuthorizationToken();
        if (authResponse.status) {
            setUserAuthStatus(userConst.AUTH_STATUS_AUTHORIZED);
        } else {
            setUserAuthStatus(userConst.AUTH_STATUS_UNAUTHORIZED);
        }
    }, []);//todo - remove this useeffect after actual signup of user

    useEffect(() => {
        // handling login and logout states
        setCurrentPathname(props.location.pathname);
        if (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) {
            getMyProfile()
                .then((res) => {
                    if (res.success) {
                        setUserProfile(res?.data);
                        setIsProfileSet(true);
                    } else {
                        if (res.statusCode === 401 || res.statusCode === 403) {
                            setIsProfileSet(true)
                        } else {
                            setIsProfileSet(false);
                            store.dispatch(showSystemErrorBlock(true));
                            setIsSiteUnderMaintenance(true)
                        }
                    }
                });
        }

        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED) {
            setUserProfile(null);
            removeAuthorizationToken();
        }
    }, [userAuthStatus]);


    useEffect(() => {// handling session-expire and access-denied states
        const {account} = props;

        if (props.userStatus === userConst.AUTH_STATUS_LOGGED_OUT) {
            props.history.replace('/session-expired');
            store.dispatch(resetLoggedOutState());
        }
        if (props.userStatus === userConst.ACCESS_DENIED) {
            if (isEmpty(account?.id)) {
                props.history.push('/access-denied', {
                    fullUrl: window.location.href,
                    prevPath: props.location.pathname
                });
            } else {
                props.history.push('/access-denied', {
                    fullUrl: window.location.href,
                    prevPath: props.location.pathname
                });
            }
            store.dispatch(resetLoggedOutState());
        }
    }, [props.userStatus]);

    useEffect(() => {
        const {appRoomJoined} = props;
        // handling session-expire and access-denied states
        const regex = /^\/onboardings\/\d+$/;
        let isOnboardingFlow =  regex.test(props.location.pathname);
        if(!isOnboardingFlow && appRoomJoined){
            props.leaveApplication()
        }
    }, [props.location.pathname]);

    const renderDrawer = () => {
        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED) {
            return null;
        }
        let contentUi = '';
        let mobileDrawerUi = (
            <Drawer
                anchor={'left'}
                open={isDrawerOpen}
                sx={{
                    width: '300px',
                    '& .MuiDrawer-paper': {
                        width: '300px',
                    },
                }}
                onClose={() => setIsDrawerOpen(false)}>
                <div style={{
                    background: '#E7ECF2',
                    height: '100vh'
                }}>
                    {contentUi}
                </div>
            </Drawer>
        );

        let desktopDrawerUi = (
            <div style={{
                width: 240,
                height: '100%',
                position: "fixed",
                background: '#E7ECF2'
            }}
                 className={"drawer-height"}>
                {contentUi}
            </div>
        );

        return (
            <div>
                {isMobileView ? mobileDrawerUi : desktopDrawerUi}
            </div>
        );


    };

    const renderAppBar = () => {
        return (<ResAppBar openDrawer={() => setIsDrawerOpen(true)}/>);
    };

    const renderContent = () => {
        return (
            <div className="w-100 h-100"
                 style={{
                     overflowY: 'auto'
                 }}>
                <Suspense fallback={(<DLLoader type={"screen"} isVisible={true}/>)}>
                    <Switch>
                        <PreLoginRoute exact path="/user/login" component={Login}/>
                        <PostLoginRoute exact path={"/"} component={HomePage}/>
                        <PostLoginRoute exact path={"/leads"} component={Leads}/> //temp
                        <PostLoginRoute exact path={"/clients"} component={Clients}/>
                        <PostLoginRoute exact path={"/leads/:leadId"} component={LeadDetails}/>
                        <PostLoginRoute exact path={"/createLeads/new"} component={LeadsCreate}/>
                        <PostLoginRoute exact path={"/onboardings/new/:onboardingApplicationId"} component={OnboardingWizard}/>
                        <PostLoginRoute exact path={"/onboardings/new"} component={Onbording}/>
                        <PostLoginRoute exact path={"/onboardings"} component={OnboardingsListing}/>
                        <PostLoginRoute exact path={"/onboardings/:onboardingApplicationId"} component={onboardingDetails}/>
                        <PostLoginRoute exact path={"/available-docs"} component={AvailableDocumentList}/>
                        <PrePostLoginRoute exact path="/session-expired" component={LoggedOutUser}/>

                        <Route exact path={"/access-denied"} component={PageNotAccessible}/>
                        <Route exact path="*" component={''}/>
                    </Switch>
                </Suspense>
                <DLToastMessage/>
            </div>
        );
    };

    const getPageMargin = () =>{
        const {isXSView} = props;
        if(isXSView){
            return "54px"
        }
        return "50px"
    };

    if(isSiteUnderMaintenance){
        return <SiteUnderMaintenance {...props}/>
    }

    const {profile, isProfileCallFired} = props;
    let authResponse = getAuthorizationToken();

    if (authResponse.status && !isEmpty(authResponse.token)) {
        if (!isProfileCallFired && isEmpty(profile)) {
            return
        }

        {
            if (!isProfileSet) {
                return (
                    <>
                        <DLToastMessage/>
                    </>
                )
            }
        }
    }

    return (
        <div style={{
            height: '100dvh'
        }}>
            {renderAppBar()}
            <div className="h-100"
                 style={{
                     paddingTop: (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) ? getPageMargin() : '0px'
                 }}>
                {isDrawerOpen && renderDrawer()}
                {renderContent()}
                <DLToastMessage/>
            </div>
        </div>
    );
};

AppNavigator.propTypes = {};

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    profile: state.userState.profile,
    isProfileCallFired: state.userState.isProfileCallFired,
    isDrawerOpen: state.appState.isDrawerOpen,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    appRoomJoined: state.appState.socket.appRoomJoined,
    userStatus: state.userState.userStatus,
});

export default connect(mapStateToProps,
    {setUserProfile, setPrevLocation, setUserAuthStatus, leaveApplication})(withRouter(AppNavigator));
