import React, {useEffect, useState} from 'react';
import DocumentUploadStatusView from './DocumentUploadStatusView';
import {connect} from "react-redux";
import {isEmpty} from "../DLComponentHelper";

const DocumentUploadStatus = (props)=> {
    const[documentUploadStatusState, setDocumentUploadStatusState] = useState({
        applicantDocuments : [],
        isMultipleApplicants : false
    });

    useEffect(()=>{
        getApplicantDocuments()
    },[props.documents]);

    const mergeDocuments = (input) => {
        let output = [];
        input.map((item,index) =>{
            let documents = item.documents;
            output= output.concat(documents)
        });
        return output

    };

    const getApplicantDocuments = () =>{
        const { documents} = props;
        if(isEmpty(documents)){
            return
        }
        const applicantDocuments = documents.reduce((acc, doc) => { //group each document with respect to applicant name

            if (!acc[doc.applicantName]) {
                acc[doc.applicantName] = {
                    applicantName: doc.applicantName,
                    documents: []
                };
            }
            acc[doc.applicantName].documents.push(doc);
            return acc;

        }, {});

        let documentsByApplicant = Object.values(applicantDocuments);
        let isMultipleApplicants = documentsByApplicant?.length > 2;

        if(!isMultipleApplicants){
            //merge all document for individual client
             documentsByApplicant=[{
                documents : mergeDocuments(documentsByApplicant)
            }];
        }

        setDocumentUploadStatusState(prevState => ({
            ...prevState,
            applicantDocuments: documentsByApplicant,
            isMultipleApplicants: isMultipleApplicants
        }));
    };

    return (
        <DocumentUploadStatusView
            {...props}
            {...documentUploadStatusState}
        />);

};

const mapStateToProps = (state)=> ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(DocumentUploadStatus);
