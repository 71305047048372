import React from 'react';
import './userDetailsCSS.css';
import DLText from "../DLText/DLText";
import DLSwitch from "../DLSwitch/DLSwitch";
import DLMenuOptions from "../DLMenuOptions/DLMenuOptions";

const UserDetailsBarView = (props) => {
    const {isMobileView, accountType} = props;

    const renderAccountType = (accountType) => {
        if (accountType === 'INDIVIDUAL') {
            return 'Individual Account';
        } else if (accountType === 'JOINT') {
            return 'Joint Account';
        }
    };

    const renderProceedWithError = () => {
        const {setProceedWithError, isProceedWithErrors, isXSView, isSMView} = props;

        if (isXSView || isSMView) {
            return <div className='d-flex align-items-center flex-shrink-0 px-1'>
                <DLMenuOptions id={'menu-opt'}
                               onSelect={() => {
                               }}
                               options={[{
                                   label: <span className='d-flex justify-content-between align-items-center gap-2 py-0'>
                                       Proceed with Errors
                                            <DLSwitch id={''}
                                                      label={''}
                                                      marginBottom={"none"}
                                                      disableHelperMessageHeight={true}
                                                      isChecked={isProceedWithErrors}
                                                      buttontype={"danger"}
                                                      onChangeValue={() => {
                                                          setProceedWithError()
                                                      }}/>
                                         </span>,
                                   value: 'PROCEEED'
                               }]}

                />
            </div>
        }

        return <div className='d-flex align-items-center flex-shrink-0'>
            <div className='mb-1'>
                <DLText id='proceed-with-errors'
                        text={'Proceed with Errors'}
                        fontColor={'grayDark'}/>
            </div>
            <div className='mx-2'>
                <DLSwitch id={''}
                          marginBottom={"none"}
                          disableHelperMessageHeight={true}
                          isChecked={isProceedWithErrors}
                          buttontype={"danger"}
                          onChangeValue={() => {
                              setProceedWithError()
                          }}/>
            </div>
        </div>
    };

    const renderHeader = () => {
        const {titleLabel} = props;
        return (
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-end'
                     style={{gap: isMobileView ? 4 : 10}}>
                    <div className='page-title p-0'>
                        {titleLabel}
                    </div>
                    <div className='page-subtitle mb-1'>
                        {renderAccountType(accountType)}
                    </div>
                </div>
                {renderProceedWithError()}
            </div>
        )
    };

    return (
        <div className='page-header'>
            {renderHeader()}
        </div>
    );
};

export default UserDetailsBarView;