export const headingData = ['Name', 'Account Type', 'Documents', 'Errors', 'KYC', 'IIN & FATCA', 'Bank Mandate','Start Date'];

export const managementHeadingData = ['Name', 'Account Type', 'Documents', 'Errors','KYC', 'IIN & FATCA','Bank Mandate', 'Start Date', 'CRM' ];

export const headingMobileData = ['Name','Documents, Forms & Errors '];

export const managementCellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '150px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '130px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '160px'
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '90px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '90px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '100px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '100px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '110px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '150px'
    },
];

export const cellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '150px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '130px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '160px'
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '90px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '100px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '100px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '100px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '110px'
    },

];

export const cellConfigMobile = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '140px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '130px'
    }
];