import React from 'react';
import "./LeadDetailsCss.css"
import "../../../assets/css/listing.css"
import {isEmpty} from "../../../core/components/DLComponentHelper";
import {convertUTCToLocalTime} from "../../../utils/helper";
import {renderLeadDetailsStatusBadge} from "../../LeadsHelper";

import DLText from "../../../core/components/DLText/DLText";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import LeadsLost from "../leadsLost/LeadsLost";
import LeadsOnHold from "../leadsOnHold/LeadsOnHold";
import AddRemark from "../LeadsAddRemark/AddRemark";
import HistoryBlock from "../../../core/components/historyBlock/HistoryBlock";
import AssignCRM from "../assignCRM/AssignCRM";
import ConfirmationModel from "./component/ConfirmationModel";

const LeadsDetailsView = (props) => {
    const {
        isMobileView, leadName, leadEmail, leadPhone, leadStatus, leadAssignedDate, getLeadSource, leadReferredBy,
        openLostModal, isShowLoader, getDetails, isOpenLostModal, isOpenOnHoldModal, closeLostModal,
        openOnHoldModal, closeOnHoldModal, getLeadId, handleOpenAddRemarkModal,handleHistoryBlockRefresh, handleOpenAssignCRMModal,
        handleConfirmationModal,isContacted, UIState
    } = props;

    const renderLostLeadModal = () => {
        if (isOpenLostModal) {
            return (
                <LeadsLost
                    handleHistoryBlockRefresh={handleHistoryBlockRefresh}
                    history={props.history}
                    getLeadId={getLeadId}
                    isOpenLostModal={isOpenLostModal}
                    openLostModal={openLostModal}
                    closeLostModal={closeLostModal}
                    getDetails={getDetails}/>
            );
        }
    };

    const renderOnHoldLeadModal = () => {
        if (isOpenOnHoldModal) {
            return (
                <LeadsOnHold
                            handleHistoryBlockRefresh={handleHistoryBlockRefresh}
                            history={props.history}
                             getLeadId={getLeadId}
                             isOpenOnHoldModal={isOpenOnHoldModal}
                             openOnHoldModal={openOnHoldModal}
                             closeOnHoldModal={closeOnHoldModal}
                             getDetails={getDetails}
                            leadStatus={leadStatus}
                             />
            );
        }
    };

    const renderAddRemarkModal = () => {
        const {isAddRemarkModalOpen} = props;

        if (isAddRemarkModalOpen) {
            return (
                <AddRemark {...props}
                />
            )
        }

    };

    const renderAssignCRMModal = () => {
        const {isAssignCRMModalOpen, onSaveSuccess} = props;
        const leadId = getLeadId();

        if (isAssignCRMModalOpen) {
            return (
                <AssignCRM {...props}
                           selectedIds={[leadId]}
                           onSaveSuccess={onSaveSuccess}
                />
            )
        }

    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isEmpty(UIState)}/>);
    };

    const renderDesktopHeader = () => {
        return (
            <div className='w-100'>
                <div className='page-header d-flex justify-content-between'>
                    <div className="d-flex align-items-center">
                        <i className='fa-solid fa-chevron-left cursor fa-2xs'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className="ps-2">
                            Lead
                        </div>
                    </div>

                </div>
                <div className='d-flex align-items-center mb-3 page-container '>
                    <div className='pe-2 border-end-black'>
                        <DLText id={'lead-name'}
                                text={leadName}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2 border-end-black cursor'>
                        <DLText id={'lead-email'}
                                fontWeight={"semi-bold"}
                                text={isEmpty(leadEmail) ? '-' :
                                    <a href={"mailto:" + leadEmail}
                                       className='link-primary'
                                       style={{textDecoration: 'underline',fontSize:'14px'}}>{leadEmail}</a>
                                }
                                marginBottom={"none"}
                                isClickable={true}
                        />
                    </div>
                    <div className='px-2 border-end-black cursor'>
                        <DLText id={'lead-phone'}
                                fontWeight={"semi-bold"}
                                text={isEmpty(leadPhone) ? '-' :
                                    <a href={"tel:" + leadPhone}
                                       className='link-primary '
                                       style={{fontSize:'14px'}}>{leadPhone}</a>
                                }
                                marginBottom={"none"}
                                isClickable={true}
                        />
                    </div>
                    <div className='px-2'>
                        {renderLeadDetailsStatusBadge(leadStatus)}
                    </div>
                </div>
            </div>
        )
    };

    const renderDesktopDetails = () => {
        return (
            <div className="lead-details lead-details-panel">
                <div>
                    <DLText
                        id={'lead-details-title'}
                        type={'normal'}
                        text={'Lead Details'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>

                <div className='d-flex pt-3 '>
                    <div>
                        <div className='d-flex gap-4 py-2'>
                            <div style={{minWidth: '120px'}}>
                                <DLText
                                    id={'lead-details-assigned'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Assigned Date'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'lead-details-assigned'}
                                    type={'normal'}
                                    text={(leadAssignedDate ==='-') ? '-': convertUTCToLocalTime(leadAssignedDate, 'DD-MM-YYYY')}
                                />
                            </div>
                        </div>

                        <div className='d-flex gap-4 py-2'>
                            <div style={{minWidth: '120px'}}>
                                <DLText
                                    id={'lead-details-assigned'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Source'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'lead-details-assigned'}
                                    type={'normal'}
                                    text={getLeadSource()}
                                />
                            </div>
                        </div>

                        <div className='d-flex gap-4 py-2'>
                            <div style={{minWidth: '120px'}}>
                                <DLText
                                    id={'lead-details-assigned'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Referred By'}
                                    fontColor={'grayDark'}
                                />

                                <DLText
                                    id={'lead-details-assigned'}
                                    type={'normal'}
                                    text={leadReferredBy}
                                />

                            </div>
                        </div>
                    </div>
                </div>
                {props.profile?.isManagement &&
                    <div className="sticky-bottom-buttons gap-0">
                        <DLButton
                            id={"initiate-onboarding-button"}
                            sideMargin={'md'}
                            styleType={"contained"}
                            onClick={() => {
                                handleOpenAssignCRMModal();
                            }}
                            label={'Assign to CRM'}
                        />
                    </div>
                }

                {props.profile.isCRM &&
                <div className="sticky-bottom-buttons gap-0">
                    {(leadStatus !== 'LOST') && <DLButton id={"lost-button"}
                                                          sideMargin={'md'}
                                                          styleType={"outlined"}
                                                          type={"danger"}
                                                          isDisabled={leadStatus === 'ONBOARDING_INITIATED'}
                                                          onClick={() => {
                                                              openLostModal()
                                                          }}
                                                          label={'Lost'}
                    />}
                    <DLButton
                        id={"on-hold-button"}
                        sideMargin={'md'}
                        styleType={"outlined"}
                        type={"black"}
                        isDisabled={leadStatus === 'ONBOARDING_INITIATED'}
                        onClick={() => {
                            openOnHoldModal()
                        }}
                        label={'On Hold'}
                    />
                    <DLButton id={'add-a_remark-button'}
                              styleType={"outlined"}
                              type={"black"}
                              sideMargin={'md'}
                              padding={'none'}
                              onClick={() => {
                                  handleOpenAddRemarkModal();
                              }}
                              label={'Add a Remark'}
                    />
                    {!isContacted && <DLButton id={'add-a_remark-button'}
                                               styleType={"outlined"}
                                               type={"black"}
                                               sideMargin={'md'}
                                               padding={'none'}
                                               isDisabled={leadStatus === 'ONBOARDING_INITIATED'}
                                               onClick={() => {
                                                   handleConfirmationModal(true);
                                               }}
                                               label={'Mark as Contacted'}
                    />}
                    <DLButton
                        id={"initiate-onboarding-button"}
                        sideMargin={'md'}
                        styleType={"contained"}
                        isDisabled={leadStatus === 'ONBOARDING_INITIATED'}
                        onClick={() => {
                            props.history.replace('/onboardings/new?leadId='+props.leadId, {leadId: props.leadId})
                        }}
                        label={'Initiate Onboarding'}
                    />

                </div>}
            </div>
        )
    };

    const renderDesktopLeadData = () => {
        return (<div className="pb-4">
            <div className=" row">
                <div className='col-6' >
                    {renderDesktopDetails()}
                </div>
                <div className=' col-6' >
                    <HistoryBlock {...props}/>
                </div>
            </div>
        </div>)
    };

    const renderDesktopUI = () => {
        return (
            <div className='lead-details-container '>
                {renderDesktopHeader()}
                <div className='lead-page-container page-container bg-light mx-3'>
                    {renderDesktopLeadData()}
                </div>
            </div>
        );
    };

    const renderMobileDetails = () => {
        return (
            <div className='lead-details-mobile'>
                <div className='lead-details-mobile-container'>
                    <DLText
                        id={'lead-details-assigned'}
                        type={'normal'}
                        text={'Assigned Date'}
                        fontColor={'grayDark'}
                        marginBottom={'sm'}
                    />
                    <DLText
                        id={'lead-details-assigned'}
                        type={'normal'}
                        text={(leadAssignedDate ==='-') ? '-':convertUTCToLocalTime(leadAssignedDate, 'DD-MM-YYYY')}
                    />
                </div>

                <div className='d-flex align-items-center justify-content-between lead-details-mobile-container my-3 '>
                    <div>
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            text={'Source'}
                            fontColor={'grayDark'}
                            marginBottom={'sm'}
                        />
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            text={getLeadSource()}
                        />
                    </div>
                    <div className='text-start'>
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            text={'Referred By'}
                            fontColor={'grayDark'}
                            marginBottom={'sm'}
                        />
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            text={leadReferredBy}
                        />
                    </div>
                </div>
                <div className='border-top'>
                    <HistoryBlock {...props}/>
                </div>

            </div>
        )
    };

    const renderMobileHeader = () => {
        return (
            <div className='page-header'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <i className='fa-solid fa-chevron-left cursor'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className='ps-3'>
                            {leadName}
                        </div>
                    </div>
                    <div>
                        {renderLeadDetailsStatusBadge(leadStatus)}
                    </div>
                </div>
                <div style={{paddingLeft: '28px'}} className='pt-1'>
                    <DLText id={'lead-email'}
                            fontWeight={"semi-bold"}
                            text={isEmpty(leadEmail) ? '-' :
                                <a href={"mailto:" + leadEmail}
                                   className='link-primary cursor'
                                   style={{fontSize: 14}}>{leadEmail}</a>
                            }
                            marginBottom={"none"}
                            isClickable={true}
                    />
                    <DLText id={'lead-phone'}
                            fontWeight={"semi-bold"}
                            text={isEmpty(leadPhone) ? '-' :
                                <a href={"tel:" + leadPhone}
                                   className='link-primary cursor'
                                   style={{fontSize: 14}}>{leadPhone}</a>
                            }

                            marginBottom={"none"}
                            isClickable={true}
                    />
                </div>
            </div>
        )
    };

    const renderMobileLeadData = () => {
        return (
            <div className="pb-4">
                <div className="pt-1 page-container">
                    {renderMobileDetails()}
                </div>
                <div className="sticky-bottom-buttons ">
                    {(leadStatus !== 'LOST') &&
                    <DLButton id={'lost-button'}
                              styleType={"contained"}
                              type={"danger"}
                              onClick={() => {
                                  openLostModal()
                              }}
                              sideMargin={'none'}
                              padding={'none'}
                              label={'Lost'}
                    />}
                    <DLButton id={'on-hold-button'}
                              styleType={"outlined"}
                              type={"black"}
                              sideMargin={'none'}

                              padding={'none'}
                              onClick={() => {
                                  openOnHoldModal()
                              }}
                              label={'On Hold'}
                    />

                    <DLButton id={'on-hold-button'}
                              styleType={"contained"}
                              type={"black"}
                              sideMargin={'none'}
                              onClick={() => {
                                  props.history.replace('/onboardings/new?leadId=' + props.leadId, {leadId: props.leadId})
                              }}
                              padding={'none'}
                              label={'Initiate Onboarding'}
                    />
                </div>
            </div>
        );
    };
    const renderConfirmationModel = () => {
        return (
            <ConfirmationModel
                {...props}
            />
        );
    };

    const renderMobileUI = () => {
        return (
            <div>
                {renderMobileHeader()}
                {renderMobileLeadData()}
            </div>
        );
    };

    if(isEmpty(UIState)){
        return renderLoader()
    };

    return (
        <div>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
            {renderLostLeadModal()}
            {renderOnHoldLeadModal()}
            {renderLoader()}
            {renderAddRemarkModal()}
            {renderAssignCRMModal()}
            {renderConfirmationModel()}
        </div>
    );
};

export default LeadsDetailsView;