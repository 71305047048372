import React, {useEffect, useState} from 'react';
import LeadsDetailsView from "./LeadsDetailsView";
import {connect} from "react-redux";
import moment from "moment";
import {getLeadDetails, getLeadSources, updateMarkedAsContacted} from "../../LeadsActions";
import {isEmpty} from "../../../utils/validations";
import {getCRMDetails} from "../../../onboarding/OnboardingActions";
import store from "../../../store";
import {setCRMDetails} from "../../leadsReducer";
import {APP_CONST} from "../../../AppComponents/AppConstants";

const LeadsDetails = (props) => {
    const [leadDetailsState, setLeadDetailsState] = useState({
        leadName: '',
        leadEmail: '',
        leadPhone: '',
        leadStatus: '',
        leadId: '',
        leadAssignedDate: '',
        leadSource: '',
        leadReferredBy: '',
        leadHistory: [],
        assignedTo: '',
        isShowLoader: false,
        sourceValues: [],
        isOpenLostModal: false,
        isOpenOnHoldModal: false,
        isAddRemarkModalOpen: false,
        isAssignCRMModalOpen: false,
        isHistoryBlockRefresh: true,
        isOpenConfirmationModal: false,
        responseError: '',
        isContacted: false,
        CRMs: [],
        UIState: ''
    });

    useEffect(() => {
        if (leadDetailsState.isHistoryBlockRefresh) {
            getDetails();
        }
    }, [leadDetailsState.isHistoryBlockRefresh]);

    const getALLCRMDetails = () => {
        const {profile} = props;
        getCRMDetails(profile.advisoryId)
            .then((res) => {
                if (res?.success) {
                    if (!isEmpty(res?.data?.advisoryUsers)) {
                        store.dispatch(setCRMDetails(res?.data?.advisoryUsers));

                        setLeadDetailsState((prevStep) => ({
                            ...prevStep,
                            CRMs: setValuesForDropdown(res.data?.advisoryUsers, "firstName", "id")
                        }));
                    }
                }
            })
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        let list = [{label: "All", value: 'ALL'}, {label: "Unassigned", value: 'UNASSIGNED'}];
        if (isEmpty(data)) {
            return;
        }

        data.map((item) => {
            list.push({label: item?.[labelToken] + " " + item?.lastName, value: item?.[valueToken]})
        });

        return list;
    };

    const getLeadId = () => {
        return props.match?.params?.leadId
    };

    const formatDataForDropDown = (data) => {
        if (isEmpty(data)) {
            return {}
        }
        return data.map(item => ({
            label: item.name,
            value: item.id
        }));

    };

    const getLeadSourceValues = () => {
        setLeadDetailsState((prevStep) => ({
            ...prevStep,
            isShowLoader: true
        }));
        getLeadSources().then((res) => {
            if (res?.success) {
                setLeadDetailsState((prevStep) => ({
                    ...prevStep,
                    sourceValues: formatDataForDropDown(res.data?.sources),
                    isShowLoader: false
                }));
            } else {
                setLeadDetailsState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false
                }));
            }
            getALLCRMDetails();
        })
    };

    const getUserName = (firstName, lastName) => {
        let userName = !isEmpty(firstName) ? firstName : '';
        if (!isEmpty(lastName)) {
            userName += ' ' + lastName;
        }
        return isEmpty(userName) ? '-' : userName
    };

    const checkIsEmpty = (value) => {
        if (isEmpty(value)) {
            return '-'
        }
        return value;
    };

    const getDetails = () => {
        const leadId = getLeadId();
        const {profile} = props;
        const advisoryId = profile?.advisoryId;
        if (isEmpty(getLeadId) || isEmpty(advisoryId)) {
            return
        }
        setLeadDetailsState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getLeadDetails(leadId, advisoryId).then(res => {
            if (res?.success) {
                const response = res?.data;
                setLeadDetailsState(prevState => ({
                    ...prevState,
                    leadName: getUserName(response?.lead?.fullName),
                    leadEmail: checkIsEmpty(response?.lead?.email),
                    leadPhone: checkIsEmpty(response?.lead?.phoneNumber),
                    leadStatus: checkIsEmpty(response?.status),
                    leadAssignedDate: checkIsEmpty(response?.assignedTS),
                    assignedTo: checkIsEmpty(response?.assignedTo),
                    leadSource: checkIsEmpty(response?.referer?.sourceType),
                    leadReferredBy: checkIsEmpty(response?.referer?.sourceName),
                    isShowLoader: false,
                    leadId: leadId,
                    UIState: APP_CONST.CONTENT_AVAILABLE
                }));
            } else {
                setLeadDetailsState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    leadId: leadId,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE
                }));
            }
            getLeadSourceValues();
        })
    };

    const handleMarkedAsContacted = () => {
        const leadId = getLeadId();
        const {profile} = props;
        const advisoryId = profile?.advisoryId;
        if (isEmpty(getLeadId) || isEmpty(advisoryId)) {
            return
        }
        setLeadDetailsState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            id: leadId
        };

        updateMarkedAsContacted(payload, advisoryId).then(res => {
            if (res?.success) {
                setLeadDetailsState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    isHistoryBlockRefresh: true,
                    responseError:'',
                }));
                handleConfirmationModal(false);
            } else {
                setLeadDetailsState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    responseError: res.__error
                }));
            }
        })
    };

    const getLeadSource = () => {
        const {sourceValues, leadSource} = leadDetailsState;

        for (let i = 0; i < sourceValues.length; i++) {
            if (sourceValues[i].value === leadSource) {
                return sourceValues[i].label;
            }
        }
        return null;
    };

    const onChangeData = (name, value) => {
        if (name === 'followUpDate') {
            let selectedDate = moment(value).format('YYYY-MM-DD');
            setLeadDetailsState(prevStep => ({
                ...prevStep,
                followUpDate: selectedDate
            }));
            return
        }
        setLeadDetailsState(prevStep => ({
            ...prevStep,
            ...prevStep,
            [name]: value,
            [name + "Error"]: ''
        }));
    };

    const openLostModal = () => {   //function for opening LOST lead modal
        setLeadDetailsState(prevState => ({
            ...prevState,
            isOpenLostModal: true
        }));
    };

    const closeLostModal = () => {  //function for closing LOST lead modal
        setLeadDetailsState(prevState => ({
            ...prevState,
            isOpenLostModal: false
        }));
    };

    const openOnHoldModal = () => { //function for opening ON HOLD lead modal
        setLeadDetailsState(prevState => ({
            ...prevState,
            isOpenOnHoldModal: true
        }));
    };

    const closeOnHoldModal = () => { //function for closing ON HOLD lead modal
        setLeadDetailsState(prevState => ({
            ...prevState,
            isOpenOnHoldModal: false
        }));
    };

    const handleOpenAddRemarkModal = () => {
        setLeadDetailsState((prevState) => ({
            ...prevState,
            isAddRemarkModalOpen: true
        }))
    };

    const handleCloseAddRemarkModal = () => {
        setLeadDetailsState((prevState) => ({
            ...prevState,
            isAddRemarkModalOpen: false
        }))
    };

    const handleHistoryBlockRefresh = (value) => {
        setLeadDetailsState((prevState) => ({
            ...prevState,
            isHistoryBlockRefresh: value
        }))
    };

    const handleOpenAssignCRMModal = () => {
        setLeadDetailsState((prevStep) => ({
            ...prevStep,
            isAssignCRMModalOpen: true
        }));
    };

    const handleCloseAssignCRMModal = () => {
        setLeadDetailsState((prevStep) => ({
            ...prevStep,
            isAssignCRMModalOpen: false
        }));
    };

    const handleConfirmationModal = (value) => {
        setLeadDetailsState((prevState) => ({
            ...prevState,
            isOpenConfirmationModal: value
        }))
    };

    const handleIsContacted = () => {
        const {isContacted} = leadDetailsState;
        if (!isContacted) {
            setLeadDetailsState((prevState) => ({
                ...prevState,
                isContacted: true
            }))
        }
    };

    const onSaveSuccess = () => {
        handleHistoryBlockRefresh(true);
    };

    return (
        <LeadsDetailsView
            {...props}
            {...leadDetailsState}
            getLeadSource={getLeadSource}
            onChangeData={onChangeData}
            openLostModal={openLostModal}
            closeLostModal={closeLostModal}
            openOnHoldModal={openOnHoldModal}
            closeOnHoldModal={closeOnHoldModal}
            getLeadId={getLeadId}
            handleIsContacted={handleIsContacted}
            handleConfirmationModal={handleConfirmationModal}
            handleMarkedAsContacted={handleMarkedAsContacted}
            handleHistoryBlockRefresh={handleHistoryBlockRefresh}
            handleOpenAddRemarkModal={handleOpenAddRemarkModal}
            handleCloseAddRemarkModal={handleCloseAddRemarkModal}
            getDetails={getDetails}
            handleOpenAssignCRMModal={handleOpenAssignCRMModal}
            handleCloseAssignCRMModal={handleCloseAssignCRMModal}
            onSaveSuccess={onSaveSuccess}
        />);
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(LeadsDetails);