import io from 'socket.io-client';
import store from '../store';
import {getAppEnvironmentVariables} from "./httpHelper";
import {
    changeAppRoomJoinedStatus,
    changeSocketStatus, changeAccountRoomJoinedStatus
} from "../AppComponents/AppReducer";
import {isEmpty} from "./validations";
import {setACHFormStatus, setIINFormStatus} from "../onboarding/onboardingFlowReducer";

let socket;

export const connect = () => {
    console.log('in connect')

    const {socketStatus} = store.getState().appState.socket;

    if (socketStatus === 'DISCONNECTED') {
        try {
            const {REACT_APP_API_URL} = getAppEnvironmentVariables();
            socket = io(REACT_APP_API_URL, {
                transports: ['websocket', 'polling']
            });
        } catch (e) {
            // store.dispatch(changeSocketStatus('DISCONNECTED'));
        }
    }


};

export const acceptNotification = () => {
    console.log('Socket io acceptNotification ');

    socket.on('connect', function () {
        console.log('inside socket connect');
        store.dispatch(changeSocketStatus('CONNECTED'));
        const {appRoomJoined} = store.getState().appState.socket;

        if (!appRoomJoined) {
            joinRoom();
        }
    });

    socket.on('disconnect', function () {
        console.log('inside socket disconnect');
        store.dispatch(changeSocketStatus('DISCONNECTED'));
        store.dispatch(changeAppRoomJoinedStatus(false));

    });

    socket.on('status', (data) => {
        console.log('$$$Received status from server: ', data);
    });
    socket.on('error', (err) => {
        store.dispatch(changeSocketStatus('DISCONNECTED'));
        store.dispatch(changeAppRoomJoinedStatus(false));
    });
    socket.on('reconnect', () => {
        console.log('Socket io reconnect event received ');
    });
    socket.on('reconnect_error', (err) => {
        console.log('inside reconnect error',err)
        store.dispatch(changeSocketStatus('DISCONNECTED'));
        store.dispatch(changeAppRoomJoinedStatus(false));
    });
    socket.on('FATKA_REGISTRATION', function (data) {
        console.log('FATKA_REGISTRATION received',data);
        store.dispatch(setIINFormStatus(true));
    });
    socket.on('IIN_STATUS', function (data) {
        console.log('IIN_STATUS received',data);
        store.dispatch(setIINFormStatus(true));
    });
    socket.on('IIN_REGISTRATION', function (data) {
        console.log('IIN_REGISTRATION received',data);
        store.dispatch(setIINFormStatus(true));
    });
    socket.on('ACH_REGISTRATION', function (data) {
        console.log('ACH_REGISTRATION received',data);
        store.dispatch(setACHFormStatus(true));
    });

};

export const disconnect = () => {
    if (socket) {
        console.log('inside socket disconnect');
        socket.disconnect();
        store.dispatch(changeSocketStatus('DISCONNECTED'));
    }
};

export const joinRoom = () => {
    console.log('in join room')

    const {appRoomJoined} = store.getState().appState.socket;
    if (appRoomJoined) {
        console.log('User room is already joined. No action required.');
    } else {
        if (socket) {
            let onboardingAppId = store.getState().onboardingFlowState?.applicationId;
            console.log('$$$ Socket Service: User joining server socket for receiving real-time notifications.');
            socket.emit("application-join", {applicationId: onboardingAppId});
            console.log('applicationId id', onboardingAppId);
            // socket.emit('join', {email: store.getState().userState?.profile?.userId});
            store.dispatch(changeAppRoomJoinedStatus(true));
        }
    }
};

export const leaveRoom = () => {
    const {socketStatus} = store.getState().appState.socket;

    if (socket && socketStatus === 'CONNECTED') {
        let onboardingAppId = store.getState().onboardingFlowState?.applicationId;
        console.log('inside leave user room');
        // console.log('Socket Service: User leaving server socket for notifications.');
        socket.emit("application-leave", {applicationId: onboardingAppId});
        store.dispatch(changeAppRoomJoinedStatus(false));
    }

};

export const joinAccountRoom = (accountId) => {
    if (isEmpty(accountId)){
        return
    }

    const {isAccountRoomJoined} = store.getState().appState.socket;
    let userId = store.getState().userState?.profile?.id;

    if (isAccountRoomJoined) {
        console.log('Account Room is already joined. No action required.');
    } else {
        if (socket) {
            console.log('Socket Service: Account joining server socket for account notifications.', accountId);
            socket.emit("account-join", {accountId: accountId, userId: userId});
            store.dispatch(changeAccountRoomJoinedStatus(true));
        } else {
            console.log('socket not present', accountId)
        }
    }
};

export const leaveAccountRoom = (accountId) => {
    if (socket) {
        let userId = store.getState().userState?.profile?.id;
        console.log('inside socket leave account room');
        if (!isEmpty(accountId)){
            socket.emit("account-leave", {accountId: accountId, userId: userId});
        }
        store.dispatch(changeAccountRoomJoinedStatus(false));
    }
};
