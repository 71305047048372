import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLPanelContainerView from "./DLPanelContainerView";

class DLPanelContainer extends Component {
  render() {
    if(this.props.hidden){
      return null;
    }
    return (
         <DLPanelContainerView
             {...this.props}
         />
    );
  }
}

DLPanelContainer.propTypes = {
  id: PropTypes.string,  // for testing purpose
  children: PropTypes.any.isRequired,
  showBorder: PropTypes.bool,
  hidden: PropTypes.bool,
  padding: PropTypes.oneOf([
    'none',
    'sm',
    'md',
    'lg'
  ]),
  marginBottom: PropTypes.oneOf([
    'none',
    'sm',
    'md',
    'lg'
  ]),
};

DLPanelContainer.defaultProps = {
  padding: 'sm',
  // marginBottom: "md",
  showBorder: false,
  hidden:false
}

export default DLPanelContainer;
