import React, {useEffect, useState} from 'react';
import CancelledChequeView from "./CancelledChequeView";
import {connect} from "react-redux";
import {
    disAssociateDocument,
    getBankAccountType, getBankData,
    getBanks,
    getDetailsByIFSC, getDocAssociateData,
    getStateList,
    updateBankAccountData
} from "../../../OnboardingActions";
import {isEmpty} from "../../../../utils/validations";
import {isAlphabetic, isIncludeSpecialCharacters} from "../../../../utils/helper";
import store from "../../../../store";
import {showToastMessage} from "../../../../AppComponents/AppActions";

const CancelledCheque = (props) => {
    const [cancelledChequeState, setCancelledChequeState] = useState({
        accountHolderDetails: {
            accountHolderName: "",
            accountNumber: "",
            bankAccountType: ""
        },
        bankDetails: {
            bankName: "",
            IFSCCode: "",
            branch: "",
            MICRNo: ""
        },
        bankAddress: {
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            stateCode: "",
            zipCode: "",
            countryCode: "IND"
        },
        downloadFileName: '',

        errors: {
            accountHolderDetails: {
                accountHolderName: "",
                accountNumber: "",
                bankAccountType: ""
            },
            bankDetails: {
                bankName: "",
                IFSCCode: "",
                branch: "",
                MICRNo: ""
            },
            bankAddress: {
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                city: "",
                stateCode: "",
                zipCode: ""
            },
            document: {
                cancelledChequeError: ''
            }
        },
        dropdownValues: {
            accountTypes: [],
            states: [],
            banks: []
        },
        isLoaderVisible: false,
        images: [],
        responseErrors: [],
        totalErrors: '',
        document: {
            documentType: 'CANCELLED_CHEQUE',
            documentCategory: 'CANCELLED_CHEQUE',
            originalFileName: '',
            uploadedFileName: '',
            filePath: '',
            id: ''
        }
    });

    useEffect(() => {
        getMasterData();
    }, [props.selectedScreenObj]);

    useEffect(() => {
        let docData = mapDocumentData(props.selectedScreenObj);
        setCancelledChequeState(prevState => ({
            ...prevState,
            document: docData,
        }));
    }, [props.selectedScreenObj.documentTypes]);

    const mapDocumentData = (selectedScreenObj) => {
        let selectedDocumentTypes = selectedScreenObj.documentTypes;

        const {document} = cancelledChequeState;
        let docData = {...document};
        if (!isEmpty(selectedDocumentTypes)) {
            //get PAN document using id from documentTypes

            let canelledCheque = selectedDocumentTypes.find((document) => document?.id === "CANCELLED_CHEQUE");
            let othersDocument = selectedDocumentTypes.find((document) => document?.id === "OTHERS");

            if (!isEmpty(canelledCheque?.documents)) {
                let documents = canelledCheque?.documents;
                docData.filePath = isEmpty(documents[0].imageFilePaths) ? '' : documents[0].imageFilePaths[0];
                docData.fileName = documents[0].fileName;
                docData.id = documents[0].id;
                docData.documentCategory = "CANCELLED_CHEQUE";
                docData.documentType = "CANCELLED_CHEQUE";
            }
            if (!isEmpty(othersDocument?.documents)) {
                let documents = othersDocument?.documents;
                docData.filePath = isEmpty(documents[0].imageFilePaths) ? '' : documents[0].imageFilePaths[0];
                docData.fileName = documents[0].fileName;
                docData.id = documents[0].id;
                docData.documentCategory = "BANK_PROOF_OTHERS";
                docData.documentType = "BANK_PROOF_OTHERS";
            }
        }
        return docData
    };

    const getMasterData = () => {
        const {profile, accountId} = props;
        let advisoryId = profile?.advisoryId;

        setCancelledChequeState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));

        getBankAccountType(advisoryId).then(typeRes => {
            if (typeRes?.success) {

                const accountTypeData = typeRes?.data?.bankAccountType;
                getStateList(advisoryId)
                    .then(stateRes => {
                        if (stateRes?.success) {
                            const stateData = stateRes?.data?.state;

                            getBanks(advisoryId)
                                .then(banksRes => {
                                    if (banksRes?.success) {
                                        const banksData = banksRes?.data?.banks;


                                        const dropdownVals = {
                                            accountTypes: setValuesForDropdown(accountTypeData, "description", "code"),
                                            states: setValuesForDropdown(stateData, "description", "code"),
                                            banks: setValuesForDropdown(banksData, "description", "code"),
                                        };

                                        getBankData(accountId, advisoryId)
                                            .then(bankResData => {
                                                if (bankResData?.success) {
                                                    const updatedData = getMappedData(bankResData.data, dropdownVals);
                                                    setCancelledChequeState(prevState => ({
                                                        ...prevState,
                                                        ...updatedData,
                                                        dropdownValues: {
                                                            ...prevState.dropdownValues,
                                                            ...dropdownVals
                                                        },
                                                        isLoaderVisible: false
                                                    }));
                                                } else {
                                                    setCancelledChequeState(prevState => ({
                                                        ...prevState,
                                                        isLoaderVisible: false
                                                    }));
                                                }
                                            });
                                    }
                                })
                        }
                    })
            }
        })
    };

    //GET CALL MAPPING
    const getMappedData = (response, dropDownValues) => {

        const {errors} = cancelledChequeState;
        const {isFromOnboardingFlow} = props;

        if (isEmpty(response)) {
            const {accountHolderDetails, bankDetails, bankAddress} = cancelledChequeState;
            let errorsObj = {
                ...errors,
                accountHolderDetails: (isFromOnboardingFlow) ? errors.accountHolderDetails :
                    validateAccountHolderDetails({
                        accountHolderDetails: accountHolderDetails,
                        errors: errors.accountHolderDetails
                    }, 'SUBMIT'),
                bankDetails: (isFromOnboardingFlow) ? errors.bankDetails :
                    validateBankDetails({bankDetails: bankDetails, errors: errors.bankDetails}, 'SUBMIT'),
                bankAddress: (isFromOnboardingFlow) ? errors.bankAddress :
                    validateBankAddress({bankAddress: bankAddress, errors: errors.bankAddress}, 'SUBMIT')
            };
            return {
                errors: {
                    ...errorsObj
                },
                scrollToFieldId: getFirstErrorFieldId(errorsObj)
            };
        }

        let accountHolderFields = cancelledChequeState.accountHolderDetails;

        let accountHolderDetails = {
            accountHolderName: isEmpty(response.accountHolderName) ? accountHolderFields.accountHolderName : response.accountHolderName,
            accountNumber: isEmpty(response.accountNumber) ? accountHolderFields.accountNumber : response.accountNumber,
            bankAccountType: isEmpty(response.bankAccountType) ? accountHolderFields.bankAccountType : getDropdownSelectedObj('accountTypes', response?.bankAccountType, dropDownValues)
        };
        let bankDetails = {
            bankName: getDropdownSelectedObj('banks', response.bankName, dropDownValues),
            IFSCCode: isEmpty(response.IFSCCode) ? '' : response.IFSCCode,
            branch: isEmpty(response.branch) ? '' : response.branch,
            MICRNo: isEmpty(response.MICRNo) ? '' : response.MICRNo,
        };
        let bankAddress = {
            addressLine1: isEmpty(response.bankAddress.addressLine1) ? '' : response.bankAddress.addressLine1,
            addressLine2: isEmpty(response.bankAddress.addressLine2) ? '' : response.bankAddress.addressLine2,
            addressLine3: isEmpty(response.bankAddress.addressLine3) ? '' : response.bankAddress.addressLine3,
            city: isEmpty(response.bankAddress.city) ? '' : response.bankAddress.city,
            stateCode: getDropdownSelectedObj('states', response.bankAddress.stateCode, dropDownValues),
            zipCode: isEmpty(response.bankAddress.zipCode) ? '' : response.bankAddress.zipCode,
            countryCode: isEmpty(response.bankAddress.countryCode) ? 'IND' : response.bankAddress.countryCode,
        };

        let accountHolderDetailsError = validateAccountHolderDetails({
            accountHolderDetails: accountHolderDetails,
            errors: errors.accountHolderDetails
        }, 'SUBMIT');
        let bankDetailsError = validateBankDetails({bankDetails: bankDetails, errors: errors.bankDetails}, 'SUBMIT');
        let bankAddressError = validateBankAddress({bankAddress: bankAddress, errors: errors.bankAddress}, 'SUBMIT');

        let errorsObj = {
            ...errors,
            accountHolderDetails: (isFromOnboardingFlow) ? errors.accountHolderDetails : accountHolderDetailsError,
            bankDetails: (isFromOnboardingFlow) ? errors.bankDetails : bankDetailsError,
            bankAddress: (isFromOnboardingFlow) ? errors.bankAddress : bankAddressError
        };

        return {
            accountHolderDetails: {...accountHolderDetails},
            bankDetails: {...bankDetails},
            bankAddress: {...bankAddress},
            errors: {
                ...errorsObj
            },
            scrollToFieldId: getFirstErrorFieldId(errorsObj)
        };
    };

    const getDropdownSelectedObj = (type, selectedValue, dropDownValues) => {
        if (isEmpty(selectedValue)) {
            return ''
        }
        return dropDownValues[type]?.find(dropdownVal => dropdownVal.value === selectedValue)
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return;
        }
        return data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });
    };

    const getBankName = (name) => {
        const {dropdownValues} = cancelledChequeState;

        return dropdownValues.banks.find((item) => {
            return (item.label === name)
        })
    };

    const getStateName = (name) => {
        const {dropdownValues} = cancelledChequeState;

        return dropdownValues.states.find((item) => {
            return (item.label.toUpperCase() === name.toUpperCase())
        })
    };

    const onSearchClick = (IFSC) => {
        const {profile} = props;
        let advisoryId = profile?.advisoryId;
        let payload = {
            IFSCCode: IFSC
        };

        const validateObject = validateChanges(cancelledChequeState, 'SUBMIT');

        getDetailsByIFSC(payload, advisoryId)
            .then(res => {
                if (res?.success) {
                    if (isEmpty(res.data?.validationErrors)) {
                        const mappedData = getMappedData(res.data, cancelledChequeState.dropdownValues);
                        setCancelledChequeState(prevState => ({
                            ...prevState,
                            ...mappedData,
                            errors: {
                                ...prevState.errors,
                                bankDetails: {
                                    ...prevState.errors.bankDetails,
                                    IFSCCode: "",
                                    bankName: "",
                                    branch: "",
                                    MICRNo: ""
                                },
                                bankAddress: {
                                    ...prevState.errors.bankAddress,
                                    addressLine1: "",
                                    addressLine2: "",
                                    addressLine3: "",
                                    city: "",
                                    stateCode: ""
                                }
                            },
                            responseErrors: '',
                            totalErrors: ''
                        }));
                    } else {
                        assignResponseError(res.data, validateObject)
                    }
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                    }
                    setCancelledChequeState(prevStep => ({
                        ...prevStep,
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length
                    }));
                }
            })
    };

    const handleChange = (name, value, fieldType) => {
        let updatedCompState = {...cancelledChequeState};

        if (fieldType === "bankAddress") {
            updatedCompState.bankAddress[name] = value;
        } else if (fieldType === "bankDetails") {
            updatedCompState.bankDetails[name] = value;
        } else if (fieldType === "accountHolderDetails") {
            updatedCompState.accountHolderDetails[name] = value;
        } else {
            updatedCompState[name] = value;
        }

        setCancelledChequeState(prevStep => ({
            ...prevStep,
            ...updatedCompState,
        }));


        const validateObject = validateChanges(updatedCompState, name, fieldType);

        setCancelledChequeState(prevStep => ({
            ...prevStep,
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));

    };

    const mapAccountHolderDetailsErrors = (errors, accountHolderDetails) => {

        let errorObj = {...errors.accountHolderDetails};
        if (!isEmpty(accountHolderDetails.accountHolderName)) {
            errorObj.accountHolderName = ''
        }
        if (!isEmpty(accountHolderDetails.accountNumber)) {
            errorObj.accountNumber = ''
        }
        if (!isEmpty(accountHolderDetails.bankAccountType)) {
            errorObj.bankAccountType = ''
        }

        return {
            accountHolderDetails: errorObj
        };
    };

    const mapBankDetailsErrors = (errors, bankDetails) => {

        let errorObj = {...errors.bankDetails};
        if (!isEmpty(bankDetails.bankName)) {
            errorObj.bankName = ''
        }
        if (!isEmpty(bankDetails.IFSCCode)) {
            errorObj.IFSCCode = ''
        }
        if (!isEmpty(bankDetails.branch)) {
            errorObj.branch = ''
        }
        if (!isEmpty(bankDetails.MICRNo)) {
            errorObj.MICRNo = ''
        }

        return {
            bankDetails: errorObj
        };
    };

    const mapbankAddressErrors = (errors, bankAddressDetails) => {

        let errorObj = {...errors.bankAddress};
        if (!isEmpty(bankAddressDetails.bankAddress.addressLine1)) {
            errorObj.addressLine1 = ''
        }
        if (!isEmpty(bankAddressDetails.bankAddress.addressLine2)) {
            errorObj.addressLine2 = ''
        }
        if (!isEmpty(bankAddressDetails.bankAddress.addressLine3)) {
            errorObj.addressLine3 = ''
        }
        if (!isEmpty(bankAddressDetails.bankAddress.city)) {
            errorObj.city = ''
        }
        if (!isEmpty(bankAddressDetails.bankAddress.stateCode)) {
            errorObj.stateCode = ''
        }
        if (!isEmpty(bankAddressDetails.bankAddress.zipCode)) {
            errorObj.zipCode = ''
        }

        return {
            bankAddress: errorObj
        };
    };

    const mapUploaderErrors = (errors, bankAddressDetails) => {

        let errorObj = {...errors.document};
        if (!isEmpty(bankAddressDetails)) {
            errorObj.cancelledChequeError = ''
        }

        return {
            document: errorObj
        };
    };

    const mapAssociateData = (response,) => {
        const {errors, dropdownValues} = cancelledChequeState;
        if (isEmpty(response)) {
            return {}
        }
        return {
            ...getMappedData(response, dropdownValues),
            errors: {
                ...mapAccountHolderDetailsErrors(errors, response),
                ...mapBankDetailsErrors(errors, response),
                ...mapbankAddressErrors(errors, response),
                ...mapUploaderErrors(errors, response)
            }
        }
    };

    const associateDocument = (type, response) => {
        const {profile, selectedScreenObj, accountId} = props;
        const {document, dropdownValues, errors} = cancelledChequeState;

        if (type === 'ERROR') {
            setCancelledChequeState(prevStep => ({
                ...prevStep,
                errors:{
                    ...errors,
                    document: {
                        cancelledChequeError: response
                    }
                }
            }));
            return
        }

        if (type === 'DELETE') {
            setCancelledChequeState(prevStep => ({
                ...prevStep,
                document: {
                    ...document,
                    filePath: '',
                    originalFileName: '',
                    uploadedFileName: '',
                    id: ''
                },
                errors: {
                    ...prevStep.errors,
                    document: {
                        ...prevStep.errors.document,
                        cancelledChequeError: ''
                    }
                },
                responseErrors: [],
                totalErrors: 0,
            }));
            return;
        }

        setCancelledChequeState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true,
        }));

        const associatePayload = {
            type: document.documentType,
            accountId: accountId,
            personId: selectedScreenObj?.personId,
            documentRequirementId: selectedScreenObj?.documentRequirementId,
            originalFileName: response?.originalFileName,
            uploadedFileName: response?.uploadedFileName
        };

        getDocAssociateData(associatePayload, profile?.advisoryId)
            .then((res) => {
                if (res.success) {
                    const mappedState = mapAssociateData(res?.data?.documentDetails, dropdownValues);
                    setCancelledChequeState(prevStep => ({
                        ...prevStep,
                        ...mappedState,
                        document: {
                            ...document,
                            filePath: (isEmpty(res.data?.imageFilePaths)) ? '' : res.data?.imageFilePaths[0],
                            fileName: res.data?.fileName,
                            id: res.data?.id
                        },
                        isLoaderVisible: false,
                        responseErrors: [],
                        totalErrors: 0,
                    }));
                } else {
                    let responseErrors = [];
                    let defaultCancelledChequeState = getResetState();
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                    }
                    setCancelledChequeState(prevStep => ({
                        ...prevStep,
                        ...defaultCancelledChequeState,
                        errors: {
                            ...prevStep.errors,
                            document: {
                                ...prevStep.errors.document,
                                cancelledChequeError: ''
                            }
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                    }));
                }
            })
    };

    const validateBankDetails = (componentState, name) => {
        const {bankDetails, errors} = componentState;
        const {bankName, IFSCCode, branch, MICRNo} = bankDetails;

        let errorObj = {...errors.bankDetails};

        if (name === 'IFSCCode' || name === 'SUBMIT') {
            if (isEmpty(IFSCCode)) {
                errorObj.IFSCCode = 'Please enter IFSC code'
            } else if (IFSCCode.length > 11) {
                errorObj.IFSCCode = 'Please enter 11 characters only'
            } else {
                errorObj.IFSCCode = ''
            }

            if (name === 'IFSCCode') {
                return errorObj
            }
        }

        if (name === 'bankName' || name === 'SUBMIT') {
            if (isEmpty(bankName)) {
                errorObj.bankName = 'Please enter bank name'
            } else if (bankName.length > 60) {
                errorObj.bankName = 'Please enter 60 characters only'
            } else {
                errorObj.bankName = ''
            }
            if (name === 'bankName') {
                return errorObj
            }
        }

        if (name === 'branch' || name === 'SUBMIT') {
            if (isEmpty(branch)) {
                errorObj.branch = 'Please enter branch'
            } else if (isAlphabetic(branch)) {
                errorObj.branch = 'Please enter alphabets only'
            } else if (branch.length > 30) {
                errorObj.branch = 'Please enter 30 alphabets only'
            } else {
                errorObj.branch = ''
            }

            if (name === 'branch') {
                return errorObj
            }
        }

        if (name === 'MICRNo' || name === 'SUBMIT') {
            if (MICRNo.length > 9) {
                errorObj.MICRNo = 'Please enter 9 numbers only'
            } else {
                errorObj.MICRNo = ''
            }

            if (name === 'MICRNo') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateBankAddress = (componentState, name) => {
        const {bankAddress, errors} = componentState;
        const {addressLine1, addressLine2, addressLine3, zipCode, stateCode} = bankAddress;

        let errorObj = {...errors.bankAddress};

        if ((name === 'addressLine1') || name === 'SUBMIT') {
            if (isEmpty(addressLine1)) {
                errorObj.addressLine1 = "Please enter address"
            } else if (addressLine1.length > 120) {
                errorObj.addressLine1 = "Please enter 120 characters only"
            } else {
                errorObj.addressLine1 = ""
            }
            if (name === 'addressLine1') {
                return errorObj
            }
        }

        if ((name === 'addressLine2') || name === 'SUBMIT') {
            if (addressLine2.length > 50) {
                errorObj.addressLine2 = "Please enter 50 characters only"
            } else {
                errorObj.addressLine2 = ""
            }
            if (name === 'addressLine2') {
                return errorObj
            }
        }

        if ((name === 'addressLine3') || name === 'SUBMIT') {
            if (addressLine3.length > 50) {
                errorObj.addressLine3 = "Please enter 50 characters only"
            } else {
                errorObj.addressLine3 = ""
            }
            if (name === 'addressLine3') {
                return errorObj
            }
        }

        if ((name === 'city') || name === 'SUBMIT') {
            if (isEmpty(bankAddress.city)) {
                errorObj.city = "Please enter city"
            } else if (bankAddress.city.length > 30) {
                errorObj.city = "Please enter 30 characters only"
            } else {
                errorObj.city = ""
            }
            if (name === 'city') {
                return errorObj
            }
        }

        if ((name === 'zipCode') || name === 'SUBMIT') {
            if (zipCode.length > 6) {
                errorObj.zipCode = "Please enter 6 characters only"
            } else {
                errorObj.zipCode = ""
            }
            if (name === 'zipCode') {
                return errorObj
            }
        }

        if ((name === 'stateCode') || name === 'SUBMIT') {
            if (isEmpty(stateCode)) {
                errorObj.stateCode = "Please select state"
            } else {
                errorObj.stateCode = ""
            }
            if (name === 'stateCode') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateAccountHolderDetails = (componentState, name) => {
        const {accountHolderDetails, errors} = componentState;
        const {accountHolderName, accountNumber, bankAccountType} = accountHolderDetails;

        let errorObj = {...errors.accountHolderDetails};

        if (name === 'accountHolderName' || name === 'SUBMIT') { // 2 more validations are pending
            if (isEmpty(accountHolderName)) {
                errorObj.accountHolderName = 'Please enter name'
            } else if (isIncludeSpecialCharacters(accountHolderName) || isAlphabetic(accountHolderName)) {
                errorObj.accountHolderName = 'Please enter alphabets only'
            } else if (accountHolderName.length > 60) {
                errorObj.accountHolderName = 'Please enter 60 alphabets only'
            } else {
                errorObj.accountHolderName = ''
            }
            if (name === 'accountHolderName') {
                return errorObj
            }
        }

        if (name === 'accountNumber' || name === 'SUBMIT') { // 1 more validations are pending
            if (isEmpty(accountNumber)) {
                errorObj.accountNumber = 'Please enter account number'
            } else if (accountNumber.length > 20) {
                errorObj.accountNumber = 'Please enter 20 numbers only'
            } else {
                errorObj.accountNumber = ''
            }

            if (name === 'accountNumber') {
                return errorObj
            }
        }

        if (name === 'bankAccountType' || name === 'SUBMIT') {
            if (isEmpty(bankAccountType)) {
                errorObj.bankAccountType = 'Please select account type'
            } else {
                errorObj.bankAccountType = ''
            }

            if (name === 'bankAccountType') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateDocument = (componentState, name) => {
        const {errors, document} = componentState;
        const {filePath} = document;

        let errorObj = {...errors.document};

        if (name === 'filePath' || name === 'SUBMIT') {
            if (isEmpty(filePath)) {
                errorObj.cancelledChequeError = 'Please select document'
            } else {
                errorObj.cancelledChequeError = ''
            }
            if (name === 'filePath') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateChanges = (componentState, name, fieldType) => {
        const {errors} = componentState;
        let errorObj = errors;
        errorObj.document = validateDocument(componentState, name);

        if (fieldType === 'bankDetails' || isEmpty(fieldType)) {
            errorObj.bankDetails = validateBankDetails(componentState, name);
        }

        if (fieldType === 'bankAddress' || isEmpty(fieldType)) {
            errorObj.bankAddress = validateBankAddress(componentState, name);
        }

        if (fieldType === 'accountHolderDetails' || isEmpty(fieldType)) {
            errorObj.accountHolderDetails = validateAccountHolderDetails(componentState, name);
        }

        return errorObj;
    };

    const simplifyObjectValues = (input) => {
        // Create a copy of the input object to avoid mutating the original object
        const output = {...input};

        // Iterate through the keys of the object
        for (const key in output) {
            // If the property's value is an object and has a 'value' key, replace it
            if (typeof output[key] === 'object' && output[key] !== null && 'value' in output[key]) {
                output[key] = output[key].value;
            }
        }
        return output;
    };

    const getResetState = () => {
        return {
            accountHolderDetails: {
                accountHolderName: "",
                accountNumber: "",
                bankAccountType: ""
            },
            bankDetails: {
                bankName: "",
                IFSCCode: "",
                branch: "",
                MICRNo: ""
            },
            bankAddress: {
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                city: "",
                stateCode: "",
                zipCode: "",
                countryCode: "IND"
            },

            errors: {
                accountHolderDetails: {
                    accountHolderName: "",
                    accountNumber: "",
                    bankAccountType: ""
                },
                bankDetails: {
                    bankName: "",
                    IFSCCode: "",
                    branch: "",
                    MICRNo: ""
                },
                bankAddress: {
                    addressLine1: "",
                    addressLine2: "",
                    addressLine3: "",
                    city: "",
                    stateCode: "",
                    zipCode: ""
                },
                document: {
                    cancelledChequeError: ''
                }
            },
            document: {
                documentType: 'CANCELLED_CHEQUE',
                documentCategory: 'CANCELLED_CHEQUE',
                originalFileName: '',
                uploadedFileName: '',
                filePath: '',
                id: ''
            }
        }
    };

    const checkIfValidateObj = () => {
        const {bankName, IFSCCode, branch} = cancelledChequeState.bankDetails;
        const {addressLine1, stateCode} = cancelledChequeState.bankAddress;
        const {accountHolderName, accountNumber, bankAccountType} = cancelledChequeState.accountHolderDetails;

        if (IFSCCode && bankName && branch && addressLine1 && stateCode && accountHolderName && accountNumber && bankAccountType) {
            return true;
        }
        return false;
    };

    const onNextClick = () => {
        const {profile, handleClickNext, isProceedWithErrors, accountId, onboardingObj} = props;
        let onboardingApplicationId = onboardingObj?.onboardingApplicationId;
        setCancelledChequeState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));

        const validateObject = validateChanges(cancelledChequeState, 'SUBMIT');

        let updatedData = {
            ...cancelledChequeState,
            ...cancelledChequeState.bankDetails,
            ...cancelledChequeState.accountHolderDetails
        };

        delete updatedData.errors;
        delete updatedData.dropdownValues;
        delete updatedData.bankDetails;
        delete updatedData.accountHolderDetails;
        delete updatedData.isLoaderVisible;
        delete updatedData.scrollToFieldId;
        delete updatedData.responseErrors;
        delete updatedData.totalErrors;
        delete updatedData.images;
        delete updatedData.document;


        let profileDataObj = {...updatedData};

        let errorId = '';
        profileDataObj = simplifyObjectValues(profileDataObj);
        profileDataObj.bankAddress = simplifyObjectValues(updatedData.bankAddress);
        profileDataObj.accountId = accountId;
        profileDataObj.onboardingApplicationId = onboardingApplicationId;

        updateBankAccountData(profileDataObj, profile?.advisoryId)
            .then(res => {
                if (res.success) {
                    if (isProceedWithErrors) {
                        handleClickNext();
                        setCancelledChequeState(prevState => ({
                            ...prevState,
                            isLoaderVisible: true
                        }));
                        return;
                    }
                    if (isEmpty(res.data?.validationErrors) && checkIfValidateObj()) {
                        handleClickNext();
                        setCancelledChequeState(prevState => ({
                            ...prevState,
                            isLoaderVisible: false
                        }));
                    } else {
                        assignResponseError(res.data, validateObject)
                    }
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                        errorId = 'page-level-errors'
                    }

                    setCancelledChequeState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                        scrollToFieldId: errorId
                    }));
                }
            })
    };

    const assignResponseError = (response, stateErrors) => {

        const errorResponse = response?.validationErrors;

        let newStateErrors = {...stateErrors};
        let responseErrors = [];

        if (isEmpty(errorResponse)) { //handled state if response status is failed
            setCancelledChequeState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...newStateErrors,
                },
                isLoaderVisible: false,
                scrollToFieldId: getFirstErrorFieldId(stateErrors),
                responseErrors: [],
                totalErrors: 0,
            }));
            return;
        }

        errorResponse.forEach(error => { //assign each field by section name
            const section = error.section;
            if (!newStateErrors[section]) {
                return;
            }
            if ((error.fields).length > 1) { //add page level validation error
                responseErrors.push(error.message)
            } else {
                //assign each field error to respected field and log if invalid field is found
                error.fields.forEach(field => {
                    newStateErrors[section][field] = error.message;
                });
            }
        });
        let errors = {
            ...cancelledChequeState.errors,
            ...newStateErrors,
        };
        setCancelledChequeState(prevStep => ({
            ...prevStep,
            errors: {
                ...prevStep.errors,
                ...newStateErrors,
            },
            responseErrors: responseErrors,
            totalErrors: responseErrors.length,
            isLoaderVisible: false,
            scrollToFieldId: isEmpty(responseErrors) ? getFirstErrorFieldId(errors) : 'page-level-errors'
        }));
    };

    const getFirstErrorFieldId = (errors) => {
        let sectionOrderToSort = ["bankDetails", "bankAddress", "accountHolderDetails"];
        for (const section of sectionOrderToSort) {
            if (errors[section]) {
                for (const key in errors[section]) {

                    const error = errors[section][key];
                    if (error && typeof error === 'object' && 'label' in error && error.label !== 'type') {
                        return section + '-' + error.label + '-id';
                    }
                    if (typeof error === 'string' && error && key !== 'type' && key !== 'parentType') {
                        return section + '-' + key + '-id';
                    }
                }
            }
        }
        return 'bank-details';
    };

    const onSaveAndCloseClick = () => {
        const {profile, handleClickSaveAndClose, saveFailureErrorMessage, accountId} = props;

        setCancelledChequeState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));

        const validateObject = validateChanges(cancelledChequeState, 'SUBMIT');

        let updatedData = {
            ...cancelledChequeState,
            ...cancelledChequeState.bankDetails,
            ...cancelledChequeState.accountHolderDetails
        };

        delete updatedData.errors;
        delete updatedData.dropdownValues;
        delete updatedData.bankDetails;
        delete updatedData.accountHolderDetails;
        delete updatedData.isLoaderVisible;
        delete updatedData.scrollToFieldId;
        delete updatedData.responseErrors;
        delete updatedData.totalErrors;
        delete updatedData.images;
        delete updatedData.document;


        let profileDataObj = {...updatedData};

        let errorId = '';
        profileDataObj = simplifyObjectValues(profileDataObj);
        profileDataObj.bankAddress = simplifyObjectValues(updatedData.bankAddress);
        profileDataObj.accountId = accountId;


        updateBankAccountData(profileDataObj, profile?.advisoryId)
            .then(res => {
                if (res.success) {
                    handleClickSaveAndClose()
                } else {
                    store.dispatch(showToastMessage('warning', saveFailureErrorMessage));
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                        errorId = 'page-level-errors'
                    }

                    setCancelledChequeState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors,
                            ...validateObject
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                        scrollToFieldId: errorId
                    }));
                }
            })
    };

    const setDocumentType = (type) => {
        let updatedCompState = {...cancelledChequeState};
        disAssociateDoc(updatedCompState, type)
    };

    const disAssociateDoc = (updatedCompState, type) => {
        const {profile} = props;
        let obj = updatedCompState?.document;
        let advisoryId = profile?.advisoryId;

        if (isEmpty(obj?.id)) {
            setCancelledChequeState(prevState => ({
                ...prevState,
                document: {
                    ...prevState.document,
                    documentType: type,
                    documentCategory: type,
                    originalFileName: '',
                    uploadedFileName: '',
                    filePath: '',
                    id: ''
                },
                errors: {
                    ...prevState.errors,
                    document: {
                        ...prevState.errors.document,
                        cancelledChequeError: ''
                    }
                }
            }));
            return;
        }

        disAssociateDocument(obj.id, advisoryId)
            .then(res => {
                if (res.success) {
                    setCancelledChequeState(prevState => ({
                        ...prevState,
                        document: {
                            ...prevState.document,
                            documentType: type,
                            documentCategory: type,
                            originalFileName: '',
                            uploadedFileName: '',
                            filePath: '',
                            id: ''
                        },
                        errors: {
                            ...prevState.errors,
                            document: {
                                ...prevState.errors.document,
                                cancelledChequeError: ''
                            }
                        }
                    }));
                }
            });

    };


    const getDownloadImageFileName = (fullName, type) => {
        if(isEmpty(fullName)){
            return ''
        }
        const words = fullName.split(' ');
        // Join the words by hyphens
        if(type === 'BANK_PROOF_OTHERS'){
            return words.join('_')+ '_BANK_PROOF';
        }
        return words.join('_')+ '_' +type;
    };

    return (
        <CancelledChequeView
            {...props}
            {...cancelledChequeState}
            onSearchClick={onSearchClick}
            handleChange={handleChange}
            onNextClick={onNextClick}
            associateDocument={associateDocument}
            onSaveAndCloseClick={onSaveAndCloseClick}
            setDocumentType={setDocumentType}
            getDownloadImageFileName={getDownloadImageFileName}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    accountId: state.onboardingFlowState.accountId,
    onboardingObj: state.onboardingFlowState.onboardingObj,
});

export default connect(mapStateToProps, {})(CancelledCheque);
