import React from 'react';
import moment from "moment";

import './../onboardingListing.css';
import {onboardingStatuses} from "../../OnboardingConsts";
import {APP_CONST} from "../../../AppComponents/AppConstants";
import {
    getBankMndateFormatedStatus,
    getFullName,
    renderKycStatusIcon,
    renderOnboardingStatusDot
} from "../../OnboardingHelper";
import {isEmpty} from "../../../core/components/DLComponentHelper";
import {cellConfigMobile, headingMobileData, managementCellConfig, managementHeadingData} from "../onboardingModel";

import DLText from "../../../core/components/DLText/DLText";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import DLDatePicker from "../../../core/components/DLDatePicker/DLDatePicker";
import DLTable from "../../../core/components/DLTable/DLTable";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

import emptyFilterFilled from "../../../assets/img/appIcons/emptyFilterFilled.png";
import emptyFilter from "../../../assets/img/appIcons/emptyFilter.png";
import clearAllFilter from "../../../assets/img/appIcons/Clear Filter.png";
import filterDot from "../../../assets/img/appIcons/filterDot.png";
import DLDropDown from "../../../core/components/DLDropdown/DLDropDown";


const s3BaseURL = process.env.REACT_APP_S3_IMAGE_PUBLIC_BASE_URL;

const ListingForManagementView = (props) => {
    const {
        UIState, onboardingList, isShowLoader, isXSView, onboardingFilter, onChangeFilter, isMobileView, listingCount,
        rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, getPaginationNextButtonStatus, skipCount,
        resetOnboardingListing, handleNewOnboarding, getCategoryValue, accountTypesDropdownValues,
        accountCategoriesDropdownValues, statusDropdownValues, CRMDropdownValues, locationDropdownValues,
        getCRMNameById
    } = props;

    const {keyword, fromDate, toDate, status, category, type, CRMId, location} = onboardingFilter;

    const getDocumentCustomUI = (documents) => {
        if (isEmpty(documents)) {
            return '-'
        }

        const isDocumentUpload = () => {
            return (
                <>
                    {documents.map((document, index) => {
                        return (
                            <div key={index}>
                                <div
                                    className={document.isUploaded ? 'isDocumentupload  bg-success border-success ' : 'isDocumentupload'}
                                    style={{border: document.isUploaded ? '0.5px' : 'solid 0.5px'}}
                                />
                            </div>
                        );
                    })}
                </>
            );
        };
        return (
            <div className="d-flex">
                <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex align-items-center">
                        {isDocumentUpload()}
                    </div>
                </div>
            </div>
        );
    };

    function isFiltersApplied(filters) {
        for (let key in filters) {
            if (filters.hasOwnProperty(key)) {
                if (Array.isArray(filters[key])) {
                    if (filters[key].length > 0) {
                        return true;
                    }
                } else if (filters[key] !== '' && key !== 'skipCount' && key !== 'limitCount' && key !== 'page' && key !== 'rowsPerPage') {
                    return true;
                }
            }
        }
        return false;
    }

    const getOnboardingNames = (fields) => {
        const names = fields?.account?.accountHolders?.map(accHolder => {
            return getFullName(accHolder?.firstName, accHolder?.middleName, accHolder?.lastName);
        });

        const applicantNames = names?.map((name, index) => {
            return <DLText key={index}
                           id={"onbarding-users"}
                           fontSize={"xs"}
                           fontWeight={"normal"}
                           text={name}
                           marginBottom={(names?.length === index + 1) ? "sm" : "xs"}
            />
        });

        if (isXSView) {
            const categoryId = isEmpty(fields?.account?.accountCategorization?.categoryId) ? '-' : fields?.account?.accountCategorization?.categoryId;
            return <div>
                {applicantNames}
                <DLText
                    id={"onbarding-users"}
                    fontSize={"xs"}
                    fontWeight={"normal"}
                    text={categoryId}
                />
            </div>
        }
        return <div className={'pt-2'}>
            {applicantNames}
        </div>;
    };

    const getOnboardingKYCStatus = (fields) => {
        let flag=false;
        let count = 0;
        const KYCStatuses = fields?.account?.accountHolders?.map(accHolder => {
            return getFullName(accHolder?.kycStatus?.description);
        });
        KYCStatuses?.forEach((name, index) => {
            if(name!=='KRA Validated' && name!=='KRA Verified' && name!=='Modification Validated')
            {
                flag=true;
                count+=1;
            }
        });

        const applicantKYCStatuses=renderKycStatusIcon(flag) ;

        const renderKycUI=(applicantKYCStatuses)=>{
            return (<div className='d-flex gap-1'>
                {applicantKYCStatuses}
                {flag && KYCStatuses.length!==1 &&
                <div>{count}/{KYCStatuses.length}
                </div>
                }
            </div>)
        };
        return <div className={''}>
            {isEmpty(applicantKYCStatuses) ? '-'
                :
                renderKycUI(applicantKYCStatuses)}
        </div>;
    };

    const getOnboardingDetailsMobile = (fields) => {
        return <div className="py-3">
            <div>{getDocumentCustomUI(fields?.document)}</div>
            <div className="d-flex my-2" style={{height: isMobileView ? '' : '29px'}}>
                <div className="details-text">
                    <DLText id={''}
                            isInline={true}
                            fontColor={'grayDark'}
                            fontSize={'xs'}
                            fontWeight={'semi-bold'}
                            text={'KYC'}/>
                    <div className='details-img-container'>
                        <div>-</div>
                    </div>
                </div>
                <div className="details-text">
                    <DLText id={''}
                            isInline={true}
                            fontColor={'grayDark'}
                            fontSize={'xs'}
                            fontWeight={'semi-bold'}
                            text={'IIN & FATCA'}/>
                    <div className='details-img-container'>
                        <div>-</div>
                    </div>

                </div>
                <div className="">
                    <DLText id={''}
                            isInline={true}
                            fontColor={'grayDark'}
                            fontSize={'xs'}
                            fontWeight={'semi-bold'}
                            text={'Bank Mandate'}/>
                    <div className='details-img-container'>
                        <div>-</div>
                    </div>

                </div>
            </div>
            <div className="my-1">
                <DLText id={''}
                        isInline={true}
                        fontColor={'danger'}
                        fontSize={'xs'}
                        fontWeight={'semi-bold'}
                        text={fields?.errorCount + ' Error'}/>
            </div>

        </div>
    };

    const renderAccountTypes = (accountType) =>{
        if(isEmpty(accountType)){
            return '-'
        }
        if(accountType === 'INDIVIDUAL'){
            return 'Individual'
        }
        if(accountType === 'JOINT'){
            return 'Joint'
        }
        return accountType
    };

    const getAccountTypeCustomUI = (fields) => {
        const accountType = renderAccountTypes(fields?.account?.accountType);
        const categoryId = isEmpty(fields?.account?.accountCategorization?.categoryId) ? '-' : getCategoryValue(fields?.account?.accountCategorization?.categoryId);
        return <div>
            <div>
                <DLText id={''}
                        isInline={true}
                        fontSize={'xs'}
                        fontWeight={'normal'}
                        text={accountType}/>
            </div>
            {(!isEmpty(categoryId)) && (categoryId !== '-') && <div>
                <DLText id={''}
                        isInline={true}
                        fontSize={'xs'}
                        fontWeight={'normal'}
                        text={categoryId}/>
            </div>}
        </div>
    };

    const getErrorCustomUI = (error) => {
        return <div>
            {error}
        </div>
    };

    const getOnboardingStatus = (selectedStatus, onboardingStatuses) => {
        if(isEmpty(selectedStatus) || isEmpty(onboardingStatuses)){
            return '-'
        }
        const status = onboardingStatuses.find(status => status.value === selectedStatus);
        if(status){
            return (
                <div className={"d-flex"}>
                    <div>
                        {renderOnboardingStatusDot(status?.value)}
                    </div>
                    <div>
                        <DLText id={''}
                                isInline={true}
                                fontSize={'xs'}
                                fontWeight={'normal'}
                                text={status.label}/>
                    </div>
                </div>
            )
        }
        else {
            return '-';
        }
    };

    const getFormattedTableData = (OnboardingList) => {
        let tableData = [];
        const {isXSView} = props;
        if (isEmpty(OnboardingList)) {
            return tableData
        }
        OnboardingList.map((fields) => {
            let OnboardingFields = [];
            let onboardingDetails = {};
            if (!isXSView) {
                OnboardingFields.push({
                    isCustomUI: true,
                    customUI: getOnboardingNames(fields)
                });
                OnboardingFields.push({
                    isCustomUI: true,
                    customUI: getAccountTypeCustomUI(fields)
                });
                OnboardingFields.push({
                    isCustomUI: true,
                    customUI: getDocumentCustomUI(fields.document)
                });
                OnboardingFields.push({
                    isCustomUI: true,
                    customUI: getErrorCustomUI(fields.errorCount)
                });
                OnboardingFields.push({
                    isCustomUI: true,
                    customUI: getOnboardingKYCStatus(fields)
                });
                OnboardingFields.push(getOnboardingStatus(fields?.status, onboardingStatuses));
                OnboardingFields.push(getBankMndateFormatedStatus(fields?.bankMandateDetails?.status));
                OnboardingFields.push(moment(fields.initiationDate).format("DD-MM-YYYY"));
                OnboardingFields.push(getCRMNameById(fields?.account?.createdBy?.id));


            } else {
                // Rendering custom ui here for the below column
                OnboardingFields.push({
                    isCustomUI: true,
                    customUI: getOnboardingNames(fields)
                });
                OnboardingFields.push({
                    isCustomUI: true,
                    customUI: getOnboardingDetailsMobile(fields)
                });
            }

            onboardingDetails.id = fields.id;
            onboardingDetails.data = OnboardingFields;
            tableData.push(onboardingDetails);
        });

        return tableData

    };

    const renderDesktopTable = () => {
        const {isShowFilterMobile, redirectToDetailsScreen} = props;
        let tableData = getFormattedTableData(onboardingList);
        if (isEmpty(onboardingList)) {
            return (
                <div className='w-100 d-flex align-items-center justify-content-center panel'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 360px)'}}>
                    No Data found.
                </div>
            )
        }
        return (
            <div className="w-100">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 425px)'}
                    tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 425px)'}
                    isRowClickable={true}
                    onRowClick={(rowId) => {
                        redirectToDetailsScreen(rowId)
                    }}
                    isDynamicHeight={true}
                    cellConfig={managementCellConfig}
                    headerData={managementHeadingData}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const renderCount = () => {
        const {isXSView, handleRefreshButton} = props;

        if (isXSView) {
            return (
                <div className=' page-container mt-4 mb-3'>
                    {!isEmpty(listingCount) &&
                    <DLText id={''}
                            isInline={true}
                            fontColor={'grayDark'}
                            fontSize={'sm'}
                            fontWeight={'semi-bold'}
                            text={'Showing ' + (listingCount) + ' records'}/>}
                </div>)
        }
        return (
            <div className={'d-flex align-items-center py-3'}>
                {!isEmpty(listingCount) &&
                <DLText id={''}
                        isInline={true}
                        fontColor={'grayDark'}
                        fontSize={'sm'}
                        marginBottom={'none'}
                        fontWeight={'semi-bold'}
                        text={'Showing ' + (listingCount) + ' records'}/>}
                <div className={'d-flex flex-grow-1 justify-content-end'}>
                    <DLButton
                        id={'filter-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        onClick={() => {handleRefreshButton()}}
                        startIcon={<i className="fa-solid fa-rotate-right"/>}/>
                </div>
            </div>)

    };

    const renderMobileTable = () => {
        const {isShowFilterMobile, redirectToDetailsScreen} = props;
        let tableData = getFormattedTableData(onboardingList);

        if (isEmpty(onboardingList)) {
            return (
                <div className='d-flex align-items-center justify-content-center empty-records'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 370px)'}}>
                    No Data found.
                </div>
            )
        }
        return (
            <div className="w-100 pagination-container">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 440px)' : 'calc(100dvh - 345px)'}
                    tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 440px)' : 'calc(100dvh - 345px)'}
                    cellConfig={cellConfigMobile}
                    headerData={headingMobileData}
                    tableData={tableData}
                    isRowClickable={true}
                    onRowClick={(rowId) => {
                        redirectToDetailsScreen(rowId)
                    }}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const renderOnboardingTable = () => {
        return (
            <div className={'w-100' + ((isMobileView && !isXSView) ? '  page-container' : '')}>
                {renderCount()}
                {isXSView ?
                    renderMobileTable()
                    :
                    <div>
                        {renderDesktopTable()}
                    </div>
                }
            </div>
        )
    };

    const renderStatusAndCRMFilter = () => {
        return (
            <div className='d-flex justify-content-between'>
                <div className={"w-100"} style={{maxWidth: "246.4px"}}>
                    <DLDropDown
                        id={"status-filter"}
                        label={'Status'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={status[0]}
                        minWidth={"100%"}
                        options={statusDropdownValues}
                        onSelect={(e) => {
                            onChangeFilter('status', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
                <div className='ms-2 w-100' style={{maxWidth: "246.4px"}}>
                    <DLDropDown
                        id={"CRM-filter"}
                        label={'CRM'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={CRMId[0]}
                        minWidth={"100%"}
                        options={CRMDropdownValues}
                        onSelect={(e) => {
                            onChangeFilter('CRMId', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
            </div>
        )
    };

    const renderCategoryAndAccountTypeFilter = () => {
        return (
            <div className='d-flex justify-content-between'>
                <div className={"w-100"} style={{maxWidth: "246.4px"}}>
                    <DLDropDown
                        id={"category-filter"}
                        label={'Category'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={category[0]}
                        minWidth={"100%"}
                        options={accountCategoriesDropdownValues}
                        onSelect={(e) => {
                            onChangeFilter('category', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
                <div className='ms-2 w-100' style={{maxWidth: "246.4px"}}>
                    <DLDropDown
                        id={"accountType-filter"}
                        label={'Account Type'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={type[0]}
                        minWidth={"100%"}
                        options={accountTypesDropdownValues}
                        onSelect={(e) => {
                            onChangeFilter('type', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
            </div>
        )
    };

    const renderDateFilter = () => {
        return (
            <div className='d-flex justify-content-between'>
                <div>
                    <DLDatePicker
                        id={'lead-from-date'}
                        value={fromDate}
                        label={'From'}
                        labelFontSize={'sm'}
                        labelFontColor={'grayDark'}
                        onChangeValue={(e) => {
                            onChangeFilter('fromDate', e)
                        }}/>
                </div>
                <div className='ms-2'>
                    <DLDatePicker
                        id={'lead-to-date'}
                        value={toDate}
                        label={'To'}
                        labelFontSize={'sm'}
                        labelFontColor={'grayDark'}
                        onChangeValue={(e) => {
                            onChangeFilter('toDate', e)
                        }}/>
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderFilter = () => {
        return (
            <div className="d-flex justify-content-between">
                <div className='d-flex' style={{flexWrap: "wrap"}}>
                    <div className='search-input me-3 mb-3'>
                        <DLInputTextField
                            id={'lead-list-search-filter'}
                            label={'Search'}
                            labelFontSize={'sm'}
                            labelFontColor={'grayDark'}
                            marginBottom={"none"}
                            rows={1}
                            styleType={"search"}
                            onChangeValue={(e) => {
                                onChangeFilter('keyword', e)
                            }}
                            value={isEmpty(keyword) ? '' : keyword}
                            placeholder={'Search by Name'}
                            isSearchable={true}
                            size="small"
                            inputHeight={'sm'}
                            inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                            inputType={"text"}
                            disableHelperMessageHeight={true}
                        />
                    </div>
                    <div className='search-input  me-3 mb-3'>
                        {renderDateFilter()}
                    </div>
                    <div className='select-filter  me-3 mb-3' style={{minWidth: "171px"}}>
                        <DLDropDown
                            id={"CRM-filter"}
                            label={'CRM'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'sm'}
                            value={CRMId[0]}
                            minWidth={"100%"}
                            options={CRMDropdownValues}
                            onSelect={(e) => {
                                onChangeFilter('CRMId', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                    <div className='select-filter  me-3 mb-3' style={{minWidth: "171px"}}>
                        <DLDropDown
                            id={"category-filter"}
                            label={'Category'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'sm'}
                            value={category[0]}
                            minWidth={"100%"}
                            options={accountCategoriesDropdownValues}
                            onSelect={(e) => {
                                onChangeFilter('category', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                    <div className='select-filter  me-3 mb-3' style={{minWidth: "171px"}}>
                        <DLDropDown
                            id={"accountType-filter"}
                            label={'Account Type'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'sm'}
                            value={type[0]}
                            minWidth={"100%"}
                            options={accountTypesDropdownValues}
                            onSelect={(e) => {
                                onChangeFilter('type', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                    <div className='select-filter  me-3 mb-3' style={{minWidth: "171px"}}>
                        <DLDropDown
                            id={"location-filter"}
                            label={'Location'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'sm'}
                            value={location[0]}
                            minWidth={"100%"}
                            options={locationDropdownValues}
                            onSelect={(e) => {
                                onChangeFilter('location', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                    <div className='select-filter me-3 mb-3' style={{minWidth: "171px"}}>
                        <DLDropDown
                            id={"status-filter"}
                            label={'Status'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'sm'}
                            value={status[0]}
                            minWidth={"100%"}
                            options={statusDropdownValues}
                            onSelect={(e) => {
                                onChangeFilter('status', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                </div>
                <div>
                    <div className='mt-3 pt-2 ms-2 text-end' style={{minWidth: "50px"}}>
                        <DLButton
                            id={'clear-all-filter-button'}
                            styleType={'outlined'}
                            type={'icon'}
                            onClick={() => resetOnboardingListing()}
                            startIcon={renderClearAllFilterIcon()}/>

                    </div>
                </div>
            </div>
        );
    };

    const renderHeaderCreateButton = () => {
        const {isMobileView, isXSView} = props;

        if (isXSView) {
            return (
                <div className={'mx-3 my-3'}>
                    <DLButton
                        id={'New-Onboarding-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        padding={'none'}
                        onClick={() => handleNewOnboarding()}
                        startIcon={<i className="fa-solid fa-plus"/>}/>
                </div>
            );
        }
        return (
            <div className={'d-flex justify-content-center pe-2 my-2' + (isMobileView ? ' page-container' : ' mx-4')}>
                <DLButton
                    id={'New-Onboarding-button'}
                    buttonSize={'sm'}
                    reduceWidth={isMobileView}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => handleNewOnboarding()}
                    label={'New Onboarding'}
                />
            </div>
        )

    };

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between pb-1 bg-white'>
                <div className='page-header'>
                    Onboardings
                </div>
                <div>
                    {renderHeaderCreateButton()}
                </div>
            </div>
        )
    };

    const renderUi = () => {
        return <div className={'h-100 d-flex justify-content-center' + (isMobileView ? '': ' bg-light ')}
                    style={{minHeight: 'calc(100dvh - 52px)'}}>
            <div className={'w-100'}>
                {renderHeader()}
                <div className={'page-container bg-white'}>
                    {renderFilter()}
                </div>
                <div className={'page-container' + (isMobileView ? '': ' bg-light ')}>
                    {renderOnboardingTable()}
                </div>
            </div>
        </div>
    };

    const renderClearAllFilterIcon = () => {
        return <img src={clearAllFilter} alt={''} height={14} width={14}/>;
    };

    const renderFilterIcon = () => {
        const {isShowFilterMobile} = props;
        const filterIcon = isShowFilterMobile ? emptyFilterFilled : emptyFilter;

        if (isFiltersApplied(onboardingFilter)) {
            return (
                <div className='icon-container'>
                    <img src={filterIcon} alt={''} height={14} width={14}/>
                    <img className='dot' alt={''} src={filterDot} height={8} width={8}/>
                </div>
            );
        }

        return <img src={filterIcon} alt={''} height={14} width={14}/>;
    };

    const renderFilterMobile = () => {
        const {isShowFilterMobile, handleToggleMobileFilter} = props;

        return <div>
            <div className='d-flex align-items-center justify-content-between gap-3 page-container '>
                <div className='search-input' style={{flex: 8}}>
                    <DLInputTextField id={'leads-searchBox'}
                                      marginBottom={"none"}
                                      rows={1}
                                      styleType={"search"}
                                      onChangeValue={(e) => {
                                          onChangeFilter('keyword', e)
                                      }}
                                      value={keyword}
                                      placeholder={'Search by Name'}
                                      isSearchable={true}
                                      size="small"
                                      inputHeight={'sm'}
                                      inputType={"text"}
                                      disableHelperMessageHeight={true}

                    />
                </div>
                <div className="d-flex align-items-center">
                    <DLButton
                        id={'filter-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        onClick={() => handleToggleMobileFilter(!isShowFilterMobile)}
                        startIcon={renderFilterIcon()}/>

                    {isFiltersApplied(onboardingFilter) &&
                    <div>
                        <i className="fa-solid fa-xmark cursor ms-2 "
                           onClick={() => resetOnboardingListing()}
                           style={{fontSize: 18}}/>
                    </div>
                    }
                </div>
            </div>
            {isShowFilterMobile &&
            <div className='bg-light mt-2' style={{paddingTop: 10, paddingBottom: 10}}>
                <div className='page-container'>
                    <div className='my-1'>{renderDateFilter()}</div>
                    <div className='my-1'>{renderStatusAndCRMFilter()}</div>
                    <div className='my-1'>{renderCategoryAndAccountTypeFilter()}</div>
                    <div className='my-1'>
                        <div className={"w-100"} style={{maxWidth: "246.4px"}}>
                            <DLDropDown
                                id={"location-filter"}
                                label={'Location'}
                                labelFontColor={'grayDark'}
                                labelFontSize={'sm'}
                                value={location[0]}
                                minWidth={"100%"}
                                options={locationDropdownValues}
                                onSelect={(e) => {
                                    onChangeFilter('location', e)
                                }}
                                disableHelperMessageHeight={true}
                                marginBottom={"none"}
                                labelPlacement={'top'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    };

    const emptyList = () => {
        return <div className='d-flex justify-content-center align-items-center gray-lg-text'
                    style={{minHeight: 'calc(100dvh - 132px)', overflow: 'hidden', fontSize: '16px'}}>
            <div className='text-center'>
                <img
                    id={'empty-onboarding-img'}
                    src={s3BaseURL + 'assets/general/emptyOnboarding.png'}
                    alt={'img'}
                    className={'img-fluid'}
                    width={'400px'}
                    height={'300px'}/>
                <DLText id={'create-first-onboarding'}
                        text={'Please initiate your first onboarding.'}
                        marginBottom={'md'}
                />
                <DLButton
                    id={'Initiate-button'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => handleNewOnboarding()}
                    label={'Initiate Onboarding'}/>
            </div>
        </div>
    };

    if (isEmpty(UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    if (UIState === APP_CONST.CONTENT_UNAVAILABLE) {
        return (
            <div className='page-container'>
                {emptyList()}
            </div>
        );
    }

    if (isMobileView) {
        return (
            <div>
                <div className={'w-100'}>
                    {renderHeader()}
                    {renderFilterMobile()}
                    {renderOnboardingTable()}
                    {renderLoader()}
                </div>
            </div>
        );
    }

    return (
        <div>
            {renderUi()}
            {renderLoader()}
        </div>

    );
};

export default ListingForManagementView;