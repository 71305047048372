import React from 'react';
import DLText from "../../../core/components/DLText/DLText";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import MinorNomineeCreateOrUpdate from "./MinorNomineeCreateOrUpdate";
import MajorNomineeCreateOrUpdate from "./MajorNomineeCreateOrUpdate";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import DLModal from "../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import appTheme from "../../../assets/appTheme";

const NomineeCreateOrUpdateView = (props) => {
    const {isMajor, setTab, onCancelClick, isShowLoader, isMobileView} = props;
    const renderSwitch = () => {
        return (
            <div className="d-flex justify-content start align-items-center pb-3">
                <div className={"me-2"}>
                    <DLText id={''}
                            text={"Nominee is"}
                            fontSize={'sm'}
                            fontColor={"grayDark"}
                            fontWeight={"normal"}
                            marginBottom={'none'}
                    />
                </div>
                <ButtonGroup style={{
                    textTransform: 'none',
                    maxHeight: '30px',
                    fontFamily: appTheme.default.fontFamily,
                    borderRadius: '25px',
                    backgroundColor: '#E8EEF8',

                }}
                             variant="outlined"
                             className='btn-group '
                             aria-label=" button group">
                    <Button id={'major-toggle'}
                            className={isMajor ? " bg-white m-1 py-1" : "py-1"}
                            style={{
                                textTransform: 'none',
                                border: 'none',
                                fontFamily: appTheme.default.fontFamily,
                                fontWeight: isMajor ? 500 : 400,
                                color: isMajor ? 'var(--primary)' : 'var(--gray-dark)',
                                borderRadius: '25px',
                                fontSize: 14,
                            }}
                            onClick={() => {
                                setTab(true)
                            }}
                    >
                        <DLText id={''}
                                text={"Major"}
                                fontSize={'sm'}
                                fontColor={isMajor ? "black" : "grayDark"}
                                fontWeight={isMajor ? "semi-bold" :"normal"}
                                marginBottom={'none'}
                        />
                    </Button>
                    <Button id={'minor-toggle'}
                            className={!isMajor ? " bg-white m-1 py-1" : "py-1"}
                            style={{
                                textTransform: 'none',
                                border: 'none',
                                fontWeight: !isMajor ? 500 : 400,
                                color: !isMajor ? 'var(--primary)' : 'var(--gray-dark)',
                                borderRadius: '25px',
                                fontSize: 14,
                            }}
                            onClick={() => {
                                setTab(false)
                            }}
                    >
                        <DLText id={''}
                                text={"Minor"}
                                fontSize={'sm'}
                                fontColor={!isMajor ? "black" : "grayDark"}
                                fontWeight={!isMajor ? "semi-bold" :"normal"}
                                marginBottom={'none'}
                        />
                    </Button>
                </ButtonGroup>
            </div>
        )
    };

    const renderUi = () => {
        if (isMajor) {
            return (
                <MajorNomineeCreateOrUpdate {...props}/>
            )
        }
        return (
            <MinorNomineeCreateOrUpdate {...props}/>
        )
    };

    const renderLoader = () =>{
        return(
            <DLLoader isVisible={isShowLoader} type={'screen'}/>
        )
    }

    const renderModal = () => {
        return (
            <div className={isMobileView ? "bg-white" : "bg-light"}>
                <div className='py-3 px-3'>
                    {renderSwitch()}
                    {renderUi()}
                    {renderLoader()}
                </div>
            </div>
        )
    };
    return (
        <div>
            <DLLoader type={"screen"}/>
            <DLModal
                history={props.history}
                maxWidth={"xs"}
                onClose={() => {onCancelClick()}}
                open={true}
                isFullScreen={true}
                id={'nominee-modal'}>
                <DLModalTitle
                    id={'nominee-modal-title'}
                    onClose={() => {onCancelClick()}}
                    title={'Add Nominee'}/>
                <DLModalContainer
                    padding={'none'}
                    id={'nominee-container'}>
                    {renderModal()}
                </DLModalContainer>
            </DLModal>
        </div>
    );
};

NomineeCreateOrUpdateView.propTypes = {};

export default NomineeCreateOrUpdateView;