export const headingData = ['Name', 'Source', 'Assigned Date', 'Status'];

export const managementHeadingData = ['','Name', 'Source & Referral', 'Created Date', 'CRM & Assigned Date', 'Status', 'Action'];

 export const headingMobileData = ['Name','Assigned Date'];

export const managementHeadingMobileData = ['','Name', 'Created Date', 'CRM & Assigned Date'];

 export const cellConfig = [
     {
         textAlign: 'left',
         width: 'auto',
         minWidth: '200px'
     },
     {
         textAlign: 'left',
         width: 'auto',
         minWidth: '200px'
     },
     {
         textAlign: 'left',
         width: 'auto',
         minWidth: '200px'
     },
     {
         textAlign: 'left',
         width: 'auto',
         minWidth: '200px'
     }
 ];

export const managementCellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '40px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '300px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '150px'
    },
    {
        textAlign: 'center',
        width: 'auto',
        minWidth: '200px'
    }
];