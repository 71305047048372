import React from 'react';
import LeadsView from "./LeadsView";


const Leads = (props) => {

    return (
        <div>
            <LeadsView {...props}/>
        </div>
    );
};

export default Leads;