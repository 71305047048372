import React, {useEffect, useState} from 'react';
import OnboardingsListingView from "./OnboardingsListingView";
import {connect} from "react-redux";


const OnboardingsListing = (props) => {
    const [onboardingsState, setOnboardingsState] = useState({
        isManagementUser: false
    })

    useEffect(() => {
        const {profile} = props;
        setOnboardingsState((prevState) => ({
            ...prevState,
            isManagementUser: profile?.isManagement
        }))
    }, [])

    return (
        <div>
            <OnboardingsListingView {...props}
                                    {...onboardingsState}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(OnboardingsListing);