import React, {Component} from 'react';
import Card from '@mui/material/Card';


let panelContainerPadding = {
  'none': '',
  'sm': 'px-2 py-3',
  'md': 'px-3 py-4',
  'lg': 'px-4 py-5'
}

let panelContainerMarginBottoms = {
  'none': 'mb-0',
  'sm': 'mb-1',
  'md': 'mb-2',
  'lg': 'mb-3'
}

class DLPanelContainerView extends Component {
  render() {
    const {children, showBorder,padding,id, marginBottom} = this.props;

    if (showBorder) {
      return (
          <Card id={id + '-panel-container'}
                className={'dl-pnl-container-padding ' + panelContainerMarginBottoms[marginBottom]}
                classes={{
            root: 'dl-panel-card',
          }}>
            <div className={panelContainerPadding[padding]}>
              {children}
            </div>
          </Card>);
    }

    return (
        <div id={id + '-panel-container'}
             className={'dl-pnl-container-padding ' + panelContainerMarginBottoms[marginBottom] + ' ' + panelContainerPadding[padding]}>
          {children}
        </div>
    );
  }
}


export default DLPanelContainerView;
